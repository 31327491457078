import { Button, Grid, MenuItem, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { format } from "date-fns";
import QuidTextField from "components/atoms/QuidTextField";
import { UpdatePeopleParams } from "entities/clients/People";
import { Director } from "entities/clients/PersonEntity";

const useStyles = makeStyles(() => ({
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
  },
  textField: {
    width: "90%",
    backgroundColor: "#FFFFFF",
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
}));

export interface DirectorInfoProps {
  director: Director;
  updateDirector: (params: UpdatePeopleParams, note: string) => Promise<void>;
}

type FormValues = {
  name?: string;
  surname?: string;
  birthDate?: string | Date;
  nationality?: string;
  countryOfBirth?: string;
  percentageOfOwnership?: string;
  note?: string;
  fiscalCode?: string;
  gender?: string;
};

const DirectorInfo: React.FC<DirectorInfoProps> = ({
  director,
  updateDirector,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("director");

  const genderArr = [
    { code: "male", text: "Male" },
    { code: "other", text: "Other" },
    { code: "female", text: "Female" },
    { code: "not_specified", text: "Not specified" },
  ];

  const onFormSubmit = (values: FormValues) => {
    return updateDirector(
      {
        id: director.person.id.toString(),
        name: values.name as string,
        surname: values.surname as string,
        birthDate: values.birthDate as string,
        nationality: values.nationality as string,
        countryOfBirth: values.countryOfBirth as string,
        gender: values.gender as string,
        fiscalCode: values.fiscalCode as string,
      },
      values.note as string
    );
  };

  const defaultValues: FormValues = {
    name: "",
    surname: "",
    birthDate: "",
    nationality: "",
    countryOfBirth: "",
    percentageOfOwnership: "",
    note: "",
    gender: "",
    fiscalCode: "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      reset({
        name: director?.person?.name,
        surname: director?.person?.surname,
        birthDate:
          director?.person?.birthDate instanceof Date
            ? format(director?.person?.birthDate, "yyyy-MM-dd")
            : (director?.person?.birthDate as string),
        nationality: director?.person?.nationality,
        countryOfBirth: director?.person?.countryOfBirth,
        percentageOfOwnership: director?.percentageOfOwnership,
        note: director?.note,
        gender: director?.person?.gender,
        fiscalCode: director?.person?.fiscalCode,
      });
    }

    return (): void => {
      isMounted = false;
    };
  }, [director, reset]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid
            container
            spacing={4}
            direction="row"
            className={classes.gridContainer}
          >
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="name"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__name__error") as string,
                }}
                label={t("details__label__name")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="surname"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__surname__error") as string,
                }}
                label={t("details__label__surname")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="birthDate"
                rules={{
                  required: t("details__label__birthDate__error") as string,
                }}
                textFieldProps={{
                  type: "date",
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                label={t("details__label__birthDate")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="nationality"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__nationality__error") as string,
                }}
                label={t("details__label__nationality")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="countryOfBirth"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__countryOfBirth__error"
                  ) as string,
                }}
                label={t("details__label__countryOfBirth")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="percentageOfOwnership"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__ownership_percentage__error"
                  ) as string,
                }}
                label={t("details__label__ownership_percentage")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={false}
                name="note"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{}}
                label={t("details__label__note")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="gender"
                rules={{}}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                }}
                label={t("details__label__gender")}
                defaultValues={defaultValues}
              >
                {genderArr.map((gender: any) => (
                  <MenuItem key={gender.code} value={gender.code}>
                    {gender.text}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={false}
                name="fiscalCode"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{}}
                label={t("details__label__fiscal_code")}
                defaultValues={defaultValues}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.updateBtn}
            variant="contained"
            type="submit"
            color="primary"
          >
            Update
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default DirectorInfo;
