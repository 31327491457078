import {
  fetchUserAccount,
  createAccount,
  validateAccountByAccountId,
  downloadBalancesDocument,
  downloadTransactionsDocument,
  getAccountBalances,
  downloadAccountDocument,
  downloadAccountBalancesDocument,
  downloadStatementsDocumentByDateRange,
  downloadOptimizedTransactionsDocument,
  downloadOptimizedBalancesDocument,
} from "api/accounts";
import { format } from "date-fns";
import {
  FetchUsersAccount,
  AccountResponse,
  CreateAccountRequest,
  DownloadAccountParams,
} from "entities/accounts/Account";
import {
  FetchBalancesParams,
  Balance,
  RawBalance,
  DownloadBalanceParams,
  DownloadStatementsParams,
} from "entities/accounts/Balance";
import { Pagination } from "entities/accounts/Pagination";
import { DownloadTransactionParams } from "entities/accounts/Transaction";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import fileDownload from "js-file-download";

export async function fetchAccountBalances(
  params: FetchBalancesParams
): Promise<{ pagination: Pagination; balances: Balance[] }> {
  try {
    const { pagination, balances } = await getAccountBalances(params);

    const balancesWithId = balances.map((balance: RawBalance) => ({
      id: balance.balance_id,
      ...balance,
    }));
    return { pagination, balances: balancesWithId };
  } catch (e) {
    throw e;
  }
}

export async function fetchUserAccountBo(
  params: FetchUsersAccount
): Promise<{ pagination: Pagination; accounts: AccountResponse[] }> {
  try {
    const { pagination, accounts } = await fetchUserAccount(params);
    const accountsWithId = accounts.map((account: AccountResponse) => ({
      id: account.account_id,
      ...account,
    }));
    return { pagination, accounts: accountsWithId };
  } catch (e) {
    throw e;
  }
}

export async function createNewAccount(
  params: CreateAccountRequest
): Promise<void> {
  try {
    const account: AccountResponse = await createAccount(params);

    const { account_id }: { account_id: number } = account;

    const res = await validateAccountByAccountId({ account_id });
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadBalancesCsv(
  params: DownloadBalanceParams
): Promise<FilePayload> {
  try {
    const res = await downloadBalancesDocument(params);
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadOptimizedBalancesCsv(
  params: DownloadBalanceParams
): Promise<FilePayload> {
  try {
    const res = await downloadOptimizedBalancesDocument(params);
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadAccountsCsv(
  params: DownloadAccountParams
): Promise<FilePayload> {
  try {
    const res = await downloadAccountDocument(params);
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadAccountBalancesCsv(
  params: DownloadTransactionParams
): Promise<FilePayload> {
  try {
    const res = await downloadAccountBalancesDocument(params);
    const dateTime = format(new Date(), "dd/MM/yyyy-HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadTransactionsCsv(
  params: DownloadTransactionParams
): Promise<FilePayload> {
  try {
    const res = await downloadTransactionsDocument(params);
    const dateTime = format(new Date(), "dd/MM/yyyy-HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadOptimizedTransactionsCsv(
  params: DownloadTransactionParams
): Promise<FilePayload> {
  try {
    const res = await downloadOptimizedTransactionsDocument(params);
    const dateTime = format(new Date(), "dd/MM/yyyy-HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadStatementsPDFByDateRange(
  params: DownloadStatementsParams
): Promise<FilePayload> {
  try {
    const res = await downloadStatementsDocumentByDateRange(params);
    const dateTime = format(new Date(), "dd/MM/yyyy-HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.pdf`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}
