import { apiClient } from "@portit/core/utils/api/apiClient";
import {
  Card,
  CardDetailsParams,
  CardListResponse,
  CardProgramsParams,
  CardResponse,
  CardsListParams,
  ProgramDetailsResponse,
  ProgramsResponse,
  TransactionsResponse,
  UploadNewBulkProgramParams,
} from "entities/cards/CardEntity";
import { handleApiClientError } from "utils";

export async function fetchCardsList(
  params: CardsListParams
): Promise<CardListResponse> {
  try {
    const res = await apiClient.get<CardListResponse>(
      `/cards/v1/cards/management`,
      "cards",
      {
        page: params.page,
        size: params.size,
        order: "id",
        orderType: "desc",
        ...(params.status && { status: params.status }),
        ...(params.partyId && { partyId: params.partyId }),
        ...(params.provider && { provider: params.provider }),
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCardsPrograms(
  params: CardsListParams
): Promise<ProgramsResponse> {
  try {
    const res = await apiClient.get<ProgramsResponse>(
      `/cards/v1/gift-cards-programs/management`,
      "cards",
      {
        page: params.page,
        size: params.size,
        order: "id",
        orderType: "desc",
        ...(params.type && { type: params.type }),
        ...(params.status && { status: params.status }),
        ...(params.currency && { currency: params.currency }),
        ...(params.programmeName && { programmeName: params.programmeName }),
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCardsProgramDetailsById(
  params: CardProgramsParams
): Promise<ProgramDetailsResponse> {
  try {
    const res = await apiClient.get<ProgramDetailsResponse>(
      `/cards/v1/gift-cards-programs/management/${params?.id}`,
      "cards"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCardById(params: CardDetailsParams): Promise<Card> {
  try {
    const res = await apiClient.get<Card>(
      `/cards/v1/cards/management/${params?.id}`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCardTransactionsById(
  params: CardDetailsParams
): Promise<TransactionsResponse> {
  try {
    const res = await apiClient.get<TransactionsResponse>(
      `/cards/v1/cards/management/${params?.id}/transactions`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCardFullTransactionsById(
  params: CardDetailsParams
): Promise<TransactionsResponse> {
  try {
    const res = await apiClient.get<TransactionsResponse>(
      `/cards/v1/cards/management/${params?.id}/full-transactions`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function suspendCardById(
  params: CardDetailsParams
): Promise<CardResponse> {
  try {
    const res = await apiClient.post<CardResponse>(
      `/cards/v1/cards/management/${params?.id}/suspend`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function activeCardById(
  params: CardDetailsParams
): Promise<CardResponse> {
  try {
    const res = await apiClient.post<CardResponse>(
      `/cards/v1/cards/management/${params?.id}/active`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function stolenCardById(
  params: CardDetailsParams
): Promise<CardResponse> {
  try {
    const res = await apiClient.post<CardResponse>(
      `/cards/v1/cards/management/${params?.id}/stolen`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function unloadCardById(
  params: CardDetailsParams
): Promise<CardResponse> {
  try {
    const res = await apiClient.post<CardResponse>(
      `/cards/v1/cards/management/${params?.id}/unload`,
      "cards",
      { amount: params?.amount }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function loadCardById(
  params: CardDetailsParams
): Promise<CardResponse> {
  try {
    const res = await apiClient.post<CardResponse>(
      `/cards/v1/cards/management/${params?.id}/load`,
      "cards",
      { amount: params?.amount }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function cancelCardById(
  params: CardDetailsParams
): Promise<CardResponse> {
  try {
    const res = await apiClient.post<CardResponse>(
      `/cards/v1/cards/management/${params?.id}/cancel`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function deactivateProgramById(
  params: CardProgramsParams
): Promise<ProgramDetailsResponse> {
  try {
    const res = await apiClient.put<ProgramDetailsResponse>(
      `/cards/v1/gift-cards-programs/management/${params?.id}/deactivate`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function activateProgramById(
  params: CardProgramsParams
): Promise<ProgramDetailsResponse> {
  try {
    const res = await apiClient.put<ProgramDetailsResponse>(
      `/cards/v1/gift-cards-programs/management/${params?.id}/activate`,
      "cards"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function uplaodNewBulkProgram(
  params: UploadNewBulkProgramParams
): Promise<{
  document: any;
}> {
  try {
    const res = await apiClient.post<{ document: any }>(
      "/cards/v1/gift-cards-programs/management",
      "cards",
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
