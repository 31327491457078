type HeaderKey = "Content-Type";

/**
 * Resolves a url
 *
 * @example
 * const resolved = urlResolver.resolve("/auth)
 * // resolved: http://adomain.com/authenticate
 */
export type UrlResolver = (source: string, realm: Realm) => string;

/**
 * Represents a realm to whom a api call belongs
 */
export type Realm =
  | "party"
  | "parties"
  | "auth"
  | "company"
  | "workflow"
  | "account"
  | "companyFormation"
  | "product"
  | "crypto"
  | "investments"
  | "cards";

export interface ApiClientConfig {
  headers?: { [k in HeaderKey | string]?: any };
  responseType?:
    | "arraybuffer"
    | "blob"
    | "document"
    | "json"
    | "text"
    | "stream"
    | undefined;
  transformResponse?: (data: any, headers?: any) => any;
  params?: { [key: string]: any };
}

export interface ApiClient {
  setToken(value: string): void;

  setProfile(value: string): void;

  get<R>(
    url: string,
    realm: Realm,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R>;

  put<R>(
    url: string,
    realm: Realm,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R>;

  post<R>(
    url: string,
    realm: Realm,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R>;

  head<R>(url: string, realm: Realm, config?: any): Promise<R>;

  patch<R>(url: string, realm: Realm, params?: any, config?: any): Promise<R>;

  delete<R>(url: string, realm: Realm, params?: any, config?: any): Promise<R>;
}

export abstract class BaseApiClient implements ApiClient {
  private resolver: UrlResolver;

  protected constructor(resolver: UrlResolver) {
    this.resolver = resolver;
  }

  get<R>(
    url: string,
    realm: Realm,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R> {
    return this.onGet(this.resolver(url, realm), params, config);
  }

  head<R>(url: string, realm: Realm, config?: any): Promise<R> {
    return this.onHead(this.resolver(url, realm), config);
  }

  patch<R>(url: string, realm: Realm, params?: any, config?: any): Promise<R> {
    return this.onPatch<R>(this.resolver(url, realm), params, config);
  }

  post<R>(
    url: string,
    realm: Realm,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R> {
    return this.onPost<R>(this.resolver(url, realm), params, config);
  }

  put<R>(
    url: string,
    realm: Realm,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R> {
    return this.onPut<R>(this.resolver(url, realm), params, config);
  }

  delete<R>(
    url: string,
    realm: Realm,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R> {
    return this.onDelete<R>(this.resolver(url, realm), params, config);
  }

  abstract onHead<R>(url: string, config?: any): Promise<R>;

  abstract onPatch<R>(url: string, params?: any, config?: any): Promise<R>;

  abstract onPut<R>(
    url: string,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R>;

  abstract onDelete<R>(
    url: string,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R>;

  abstract onGet<R>(
    url: string,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R>;

  abstract onPost<R>(
    url: string,
    params?: any,
    config?: ApiClientConfig
  ): Promise<R>;

  abstract setToken(value: string): void;

  abstract setProfile(value: string): void;
}
