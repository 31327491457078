import {
  fetchBankAccountTypes,
  fetchIdentificationTypes,
  fetchPaymentStatus,
  fetchTransactionStatus,
  fetchAccountTypes,
  fetchTransactionTypes,
  fetchTransactionInboundTypes,
  fetchTransactionOutboundTypes,
  fetchTransactionDirection,
  fetchPaymentTypes,
  fetchCurrencies,
  fetchCountry,
  fetchLegalEntities,
  fetchAccountStatus,
  fetchRoutingCodeTypes,
  fetchTransactionSubtypes,
} from "api/accountDatasets";
import { AccountDatasets } from "entities/accounts/AccountDataset";

export async function fetchAllAccountDatasets(): Promise<AccountDatasets> {
  try {
    let res = null;

    if (process.env.REACT_APP_PROJECT === "toonie") {
      res = await Promise.all<any>([
        fetchBankAccountTypes(),
        fetchIdentificationTypes(),
        fetchPaymentStatus(),
        fetchTransactionStatus(),
        fetchAccountTypes(),
        fetchTransactionTypes(),
        fetchTransactionInboundTypes(),
        fetchTransactionOutboundTypes(),
        fetchTransactionDirection(),
        fetchPaymentTypes(),
        fetchCurrencies(),
        fetchCountry(),
        fetchLegalEntities(),
        fetchAccountStatus(),
        fetchRoutingCodeTypes(),
        fetchTransactionSubtypes(),
      ]);
    } else {
      res = await Promise.all<any>([
        fetchBankAccountTypes(),
        fetchIdentificationTypes(),
        fetchPaymentStatus(),
        fetchTransactionStatus(),
        fetchAccountTypes(),
        fetchTransactionTypes(),
        fetchTransactionInboundTypes(),
        fetchTransactionOutboundTypes(),
        fetchTransactionDirection(),
        fetchPaymentTypes(),
        fetchCurrencies(),
        fetchCountry(),
        fetchLegalEntities(),
        fetchAccountStatus(),
        fetchRoutingCodeTypes(),
      ]);
    }

    const mapRes: any = res?.map(({ values }) => values);

    const [
      bankAccountTypes,
      identificationTypes,
      paymentStatus,
      transactionStatus,
      accountTypes,
      transactionTypes,
      transactionInboundTypes,
      transactionOutboundTypes,
      transactionDirection,
      paymentTypes,
      currencies,
      countries,
      legalEntities,
      accountStatus,
      routingCodeTypes,
      transactionSubtypes,
    ] = mapRes;

    return {
      bankAccountTypes,
      identificationTypes,
      paymentStatus,
      transactionStatus,
      accountTypes,
      transactionTypes,
      transactionInboundTypes,
      transactionOutboundTypes,
      transactionDirection,
      paymentTypes,
      currencies,
      countries,
      legalEntities,
      accountStatus,
      routingCodeTypes,
      transactionSubtypes,
    };
  } catch (e) {
    throw e;
  }
}
