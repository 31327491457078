import React, { ChangeEvent, useEffect, useState, useMemo } from "react";
import {
  Button,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAccountStatus, getAccountTypes } from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { AccountResponse } from "../entities/accounts/Account";
import { handleFailure } from "resHandlers";
import { ServerFailure } from "features/core/Failure";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useHistory } from "react-router";
import {
  getAccountRate,
  createTemporaryRate,
  fetchUserAccount,
} from "api/accounts";
import { AccountDataset } from "entities/accounts/AccountDataset";
import { Balance } from "entities/accounts/Balance";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { debounce } from "lodash";
import RefreshIcon from "components/atoms/icons/RefreshIcon";

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: "#FAFAFA" },
  containerInner: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  yourExchangeRateCn: {
    display: "flex",
    rowGap: 4,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  column4: { width: "calc(100% / 3 - 30px)", padding: "0px 15px" },
  amountdetails: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  refreshButtonCn: {
    marginTop: 32,
    display: "flex",
    rowGap: 16,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  containerInnerButtons: {
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 60,
  },
  containerAmountAndButtons: {
    marginTop: 60,
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  checkText: {
    textAlign: "center",
    paddingTop: 13,
    fontFamily: "Nunito",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 18,
  },
  accountDetailsWrapper: {
    background: "#fff",
    padding: 15,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
  textField: {
    background: "#ffffff",
    marginBottom: 15,
  },
}));

const PaymentsInternalConversion: React.FC = () => {
  const { t } = useTranslation("payments");

  const [accounts, setAccounts] = useState<AccountResponse[]>([]);
  const [account, setAccount] = useState<AccountResponse>();
  const [search, setSearch] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [fromAmount, setFromAmount] = useState("");
  const [exchageRate, setExchangeRate] = useState("");
  const [temporaryRateId, setTemporaryRateId] = useState(-1);
  const [isFromSelected, setIsFromSelected] = useState(false);
  const [currencyFrom, setCurrencyFrom] = useState("");
  const [currencyTo, setCurrencyTo] = useState("");
  const [balance, setBalance] = useState({} as Balance | undefined);
  const [operation, setOperation] = useState<"buy" | "sell" | undefined>();
  const [countdown, setCountdown] = useState(10);
  const [countdownId, setCountdownId] = useState<
    ReturnType<typeof setInterval>
  >();
  const [isLoading, setIsLoading] = useState({
    countdown: false,
    fetch: false,
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultValues = {
    account_type: "",
    account_status: "enabled",
    amount: fromAmount,
    currencyto: "",
    currencyFrom: "",
  };

  const accountDetails = {
    account_name: account?.account_name || "n/a",
    account_holder: account?.account_holder || "n/a",
    quid_reference: account?.quid_reference || "n/a",
    account_type: account?.account_type || "n/a",
    street: account?.street || "n/a",
    city: account?.city || "n/a",
    postal_code: account?.postal_code || "n/a",
    country: account?.country || "n/a",
    state_or_province: account?.state_or_province || "n/a",
    account_status: account?.status || "n/a",
  };

  const transactionDetails = {
    current_amount:
      `${MoneyFormatter(
        balance?.current_amount || 0,
        currencyFrom as DineroFactory.Currency
      )}` || "n/a",
    available_amount:
      `${MoneyFormatter(
        balance?.available_amount || 0,
        currencyFrom as DineroFactory.Currency
      )}` || "n/a",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, watch, reset } = methods;

  const accountTypes = useSelector(getAccountTypes);
  const accountStatuses = useSelector(getAccountStatus);
  const accountType = watch("account_type");
  const accountStatus = watch("account_status");

  const onResetForm = () => {
    reset(defaultValues);
    setAccount(undefined);
    setBalance(undefined);
    setCurrencyFrom("");
    setCurrencyTo("");
    setSearch("");
    setIsFromSelected(false);
  };

  useEffect(() => {
    if (currencyFrom) {
      setBalance(
        account?.balances?.filter(
          (balance: Balance) => balance?.currency === currencyFrom
        )[0]
      );
    }
  }, [currencyFrom]);

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        if (accountStatus !== "" && accountType !== undefined) {
          const { accounts } = await fetchUserAccount({
            type: accountType,
            status: accountStatus,
            ...(search && { keyword: search }),
            size: TABLE_PAGE_SIZE,
          });
          setAccounts(accounts);
        }
      } catch (err: any) {
        handleFailure(err);
      }
    };

    void getAllUsers();
  }, [search, accountType, accountStatus]);

  useEffect(() => {
    if (account) {
      setIsFromSelected(true);
    }
  }, [account]);

  const onFormSubmit = async () => {
    if (account?.account_id !== undefined && temporaryRateId !== -1) {
      try {
        if (fromAmount <= ((balance?.available_amount as unknown) as string)) {
          await createTemporaryRate(
            temporaryRateId,
            account.owner_id as number
          );
          dispatch(
            promptsSlice.actions.openSnackbar({
              message: t("payment__success__message"),
              type: "success",
            })
          );
          history.push("/conversions/list");
        } else {
          dispatch(
            promptsSlice.actions.openSnackbar({
              message: "Insufficient balance",
              type: "warning",
            })
          );
        }
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    }
  };

  const resetField = () => {
    setToAmount("");
    setFromAmount("");
    setExchangeRate("");
  };

  const resetCountdown = () => {
    setCountdown(10);
    setIsLoading({
      fetch: false,
      countdown: false,
    });
    clearInterval(countdownId);
  };

  const runCountdown = () => {
    let count = 11;
    setIsLoading((prev) => ({ ...prev, countdown: true }));

    const countdownId = setInterval(function () {
      count--;
      setCountdown(count);
      setCountdownId(countdownId);
      if (count === 0) {
        clearInterval(countdownId);
        setIsLoading((prev) => ({ ...prev, countdown: false }));
      }
    }, 1000);
  };

  const getExchangeRate = async ({
    toAmount,
    operation,
    fromAmount,
    currencyTo,
    currencyFrom,
  }: {
    currencyTo: string;
    currencyFrom: string;
    operation: "buy" | "sell";
    toAmount: string | number;
    fromAmount: string | number;
  }) => {
    if (
      currencyTo &&
      currencyFrom &&
      operation?.length > 0 &&
      currencyTo !== currencyFrom
    ) {
      setIsLoading((prev) => ({ ...prev, fetch: true }));
      try {
        const res = await getAccountRate({
          operation,
          buy_currency: currencyTo,
          sell_currency: currencyFrom,
          partyId: account?.owner_id || 0,
          amount: fromAmount !== -1 ? fromAmount : toAmount,
        });

        if (res?.client_rate && res?.temporary_rate_id) {
          setExchangeRate(res.client_rate);
          setTemporaryRateId(res.temporary_rate_id);
          runCountdown();
          if (fromAmount !== -1 && toAmount === -1) {
            setToAmount(res?.client_buy_amount?.toString());
          } else if (fromAmount === -1 && toAmount !== -1) {
            setFromAmount(res?.client_sell_amount?.toString());
          } else {
            setToAmount("0");
            setFromAmount("0");
          }
        } else {
          setExchangeRate("0");
          setTemporaryRateId(-1);
        }
      } catch (err: any) {
        handleFailure(err);
      } finally {
        setIsLoading((prev) => ({ ...prev, fetch: false }));
      }
    }
  };

  // From amount -> To amount
  // Operation: sell
  const debouncedFromAmount = useMemo(
    () =>
      debounce(({ fromAmount }) => {
        if (fromAmount > 0) {
          return getExchangeRate({
            fromAmount,
            currencyTo,
            currencyFrom,
            toAmount: -1,
            operation: "sell",
          });
        }
      }, 3000),
    [currencyTo, currencyFrom]
  );

  // To amount -> From amount
  // Operation: buy
  const debouncedToAmount = useMemo(
    () =>
      debounce(({ toAmount }) => {
        if (toAmount > 0) {
          return getExchangeRate({
            toAmount,
            currencyTo,
            currencyFrom,
            fromAmount: -1,
            operation: "buy",
          });
        }
      }, 3000),
    [currencyTo, currencyFrom]
  );

  const onRefreshClick = () => {
    if (operation && toAmount && fromAmount) {
      if (operation === "buy") {
        getExchangeRate({
          toAmount,
          operation,
          currencyTo,
          currencyFrom,
          fromAmount: -1,
        });
      } else if (operation === "sell") {
        getExchangeRate({
          operation,
          fromAmount,
          currencyTo,
          currencyFrom,
          toAmount: -1,
        });
      } else {
        return;
      }
    }
  };

  const onCancelClick = () => {
    resetField();
    resetCountdown();
  };

  return (
    <MainTemplate>
      <QuidTitle>{t("internal__conversion__page__title")}</QuidTitle>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormProvider {...methods}>
            <div className={classes.containerInner}>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__conversion__head__account__label")}
                </QuidTitle>
                {!isFromSelected === true && (
                  <div>
                    <QuidTextField
                      name="account_type"
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                      }}
                      rules={{
                        required: t("from__account__type__required") as string,
                      }}
                      defaultValues={defaultValues}
                      label={t("from__account__type")}
                    >
                      <MenuItem value="">{t("from__account__type")}</MenuItem>
                      {accountTypes?.map((option: AccountDataset, index) => (
                        <MenuItem
                          key={`${option}-${index}`}
                          value={option.code}
                        >
                          {option.text}
                        </MenuItem>
                      ))}
                    </QuidTextField>
                    {process.env.REACT_APP_KEYCLOAK_REALM === "toonie" &&
                      accountType && (
                        <QuidTextField
                          name="account_status"
                          textFieldProps={{
                            select: true,
                            fullWidth: true,
                          }}
                          rules={{
                            required: t(
                              "from__account__status__required"
                            ) as string,
                          }}
                          defaultValues={defaultValues}
                          label={t("from__account__status")}
                        >
                          {accountStatuses?.map(
                            (option: AccountDataset, index) => (
                              <MenuItem
                                key={`${option}-${index}`}
                                value={option.code}
                              >
                                {option.text}
                              </MenuItem>
                            )
                          )}
                        </QuidTextField>
                      )}
                    <TextField
                      label={t("filter__search")}
                      name="from_search"
                      variant="outlined"
                      fullWidth
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setSearch(e.target.value)
                      }
                      value={search}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div className={classes.searchBox}>
                              <SearchIcon color="#fff" />
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {accounts?.map(
                      (account: AccountResponse, index: number) => (
                        <div
                          key={index}
                          onClick={() => {
                            setAccount(account);
                          }}
                          className={classes.accountDetailsWrapper}
                        >
                          <div>
                            <Typography variant="body1">
                              {account.account_name}
                              <br />
                              <small>{account.account_holder}</small>
                            </Typography>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
                {account && <DetailsBlock label="" toPrint={accountDetails} />}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__conversion__head__currencies__label")}
                </QuidTitle>
                {account && (
                  <div>
                    <QuidTextField
                      rules={{
                        required: t("currencyFrom__required") as string,
                      }}
                      disabled={isLoading.countdown || isLoading.fetch}
                      name="currencyFrom"
                      defaultValues={defaultValues}
                      label={t("internal__conversion__currency_from")}
                      textFieldProps={{ select: true, fullWidth: true }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCurrencyFrom(e.target.value);
                        resetField();
                      }}
                    >
                      {account?.balances?.map(
                        (balance: Balance, index: number) => {
                          return (
                            <MenuItem
                              key={`${balance.currency}-${index}`}
                              value={balance.currency}
                            >
                              {balance?.currency}
                            </MenuItem>
                          );
                        }
                      )}
                    </QuidTextField>
                    <QuidTextField
                      rules={{
                        required: t("currencyTo__required") as string,
                      }}
                      disabled={isLoading.countdown || isLoading.fetch}
                      name="currencyTo"
                      defaultValues={defaultValues}
                      label={t("internal__conversion__currency_to")}
                      textFieldProps={{ select: true, fullWidth: true }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCurrencyTo(e.target.value);
                        resetField();
                      }}
                    >
                      {account?.balances?.map(
                        (balance: Balance, index: number) => {
                          return (
                            <MenuItem
                              key={`${balance.currency}-${index}`}
                              value={balance.currency}
                            >
                              {balance?.currency}
                            </MenuItem>
                          );
                        }
                      )}
                    </QuidTextField>
                  </div>
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__conversion__head__details__label")}
                </QuidTitle>
                {currencyFrom && currencyTo && currencyFrom !== currencyTo ? (
                  <>
                    <TextField
                      label={t("internal__conversion__amount_from")}
                      name="amount"
                      type="number"
                      fullWidth={true}
                      value={fromAmount}
                      onChange={(e) => {
                        setToAmount("");
                        setExchangeRate("");
                        setOperation("sell");
                        setFromAmount(e?.target?.value);
                        debouncedFromAmount({
                          ignore: false,
                          fromAmount: e?.target?.value,
                          currencyTo,
                          currencyFrom,
                        });
                      }}
                      disabled={isLoading.countdown || isLoading.fetch}
                      variant="outlined"
                      margin="none"
                      className={classes.textField}
                    />
                    <TextField
                      label={t("internal__conversion__amount_to")}
                      type="number"
                      fullWidth={true}
                      disabled={isLoading.countdown || isLoading.fetch}
                      value={toAmount}
                      onChange={(e) => {
                        setFromAmount("");
                        setExchangeRate("");
                        setOperation("buy");
                        setToAmount(e?.target?.value);
                        debouncedToAmount({
                          ignore: false,
                          toAmount: e?.target?.value,
                          currencyTo,
                          currencyFrom,
                        });
                      }}
                      variant="outlined"
                      margin="none"
                      className={classes.textField}
                    />
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      rules={{
                        required: t("reason__required") as string,
                      }}
                      name="reason"
                      label={t("internal__conversion__form__reason__label")}
                      defaultValues={defaultValues}
                    />
                    {exchageRate ? (
                      <div className={classes.yourExchangeRateCn}>
                        <Typography variant="body1">
                          {t("internal__conversion__your_exchange_rate")}
                        </Typography>
                        <Typography variant="body1" color="primary">
                          {exchageRate}
                        </Typography>
                      </div>
                    ) : null}
                    {fromAmount && toAmount ? (
                      <div className={classes.refreshButtonCn}>
                        {countdown > 0 ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={onCancelClick}
                          >
                            {t("internal__conversion__cancel__button")}
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={onRefreshClick}
                            startIcon={<RefreshIcon color="#133D59" />}
                          >
                            {t("internal__conversion__refresh__button")}
                          </Button>
                        )}
                        {countdown > 0 && (
                          <Typography variant="body1">
                            {t("internal__conversion__refresh__countdown", {
                              countdown,
                            })}
                          </Typography>
                        )}
                        {countdown === 0 && (
                          <Typography variant="body1">
                            {t("internal__conversion__refresh__info")}
                          </Typography>
                        )}
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
              <div className={classes.containerAmountAndButtons}>
                {balance && currencyFrom && (
                  <div className={classes.amountdetails}>
                    <div className={classes.column4}>
                      <QuidTitle fontSize={18} weight={500}>
                        {t("internal__conversion__head__amounts__label")}
                      </QuidTitle>
                      <DetailsBlock label="" toPrint={transactionDetails} />
                    </div>
                  </div>
                )}
                <div className={classes.containerInnerButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!(isLoading.countdown || isLoading.fetch)}
                  >
                    {t("internal__conversion__create__button")}
                  </Button>
                  <Button
                    disabled={isLoading.countdown || isLoading.fetch}
                    variant="contained"
                    color="secondary"
                    onClick={onResetForm}
                  >
                    {t("internal__conversion__reset__button")}
                  </Button>
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
    </MainTemplate>
  );
};

export default PaymentsInternalConversion;
