import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import ApproveRejectButtons from "./ApproveRejectButtons";
import { FormProvider, useForm } from "react-hook-form";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";
import { useTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import QuidFormControl from "components/atoms/QuidFormControl";
import { isEmpty } from "lodash";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  usefulLinks: {
    justifyContent: "center",
  },
  textarea: {
    marginTop: 18,
    marginBottom: 22,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    padding: 50,
    backgroundColor: "#FAFAFA",
    paddingTop: 100,

    "& .box": {
      display: "flex",
      flexWrap: "wrap",
      padding: "50px 100px",
      backgroundColor: "#FFF",
      borderRadius: 60,

      "& .MuiFormControlLabel-root, & .MuiTextField-root, & .MuiNumberField-root": {
        width: "100%",
      },

      "& .reject": {
        textAlign: "center",
        marginBottom: 20,
        color: "#FF6F0F",
      },

      "& .actions": {
        minHeight: 50,
        margin: "0 10px",

        "&-reject": {
          backgroundColor: "#FF6F0F",
        },
      },
    },
  },
}));

interface ApproveRejectBoxSimpleProps {
  checks?: any;
  accept: (values: VerificationRequest) => Promise<unknown>;
  reject: (values: VerificationRequest) => Promise<unknown>;
}

const ApproveRejectBoxSimple = ({
  accept,
  reject,
  checks,
}: ApproveRejectBoxSimpleProps) => {
  const { t } = useTranslation("validations");

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const methods = useForm({
    mode: "onBlur",
  });

  const onReject = async (values: VerificationRequest) => {
    try {
      await reject(values);
      handleSuccessfulMessage("Action received");
      history.replace("/");
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const onAccept = async (values: VerificationRequest) => {
    if (!Object.keys(values.checks).every((c) => values.checks[c])) {
      dispatch(
        promptsSlice.actions.openSnackbar({
          message:
            "To accept, all the checks must be marked and the other fields empty",
          type: "error",
        })
      );
      return;
    }
    try {
      await accept(values);
      handleSuccessfulMessage("Action received");
      history.replace("/");
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Grid item xs={12} className={classes.container}>
          <Grid item xs={10} className="box">
            {checks ? (
              <Grid item xs={6}>
                {Object.keys(checks).map((c: string, i: number) => (
                  <QuidFormControl
                    key={`checkbox-verification-${i}`}
                    formControl={<Checkbox color="primary" />}
                    name={`checks.${c}`}
                    defaultValues={checks}
                    label={t("check_to_confirm")}
                  />
                ))}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <ApproveRejectButtons
                disabled={
                  !checks
                    ? false
                    : isEmpty(methods.watch("checks")) ||
                      !Object.values(methods.watch("checks")).every(
                        (c) => c === true
                      )
                }
                onAccept={methods.handleSubmit(onAccept)}
                onReject={methods.handleSubmit(onReject)}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ApproveRejectBoxSimple;
