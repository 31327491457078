import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAccountStatus, getAccountTypes } from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { AccountResponse } from "../entities/accounts/Account";
import { handleFailure } from "resHandlers";
import { ServerFailure } from "features/core/Failure";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useHistory } from "react-router";
import { fetchUserAccount } from "api/accounts";
import { createUpTransaction } from "api/payments";
import { AccountDataset } from "entities/accounts/AccountDataset";
import { Balance } from "entities/accounts/Balance";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import { OPERATIONS, TABLE_PAGE_SIZE } from "shared/constants";
import QuidFormControl from "components/atoms/QuidFormControl";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: "#FAFAFA" },
  containerInner: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  column4: { width: "calc(100% / 2 - 60px)", padding: "0px 15px" },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  containerInnerButtons: {
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 60,
  },
  checkText: {
    textAlign: "center",
    paddingTop: 13,
    fontFamily: "Nunito",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 18,
  },
  accountDetailsWrapper: {
    background: "#fff",
    padding: 15,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
}));

interface FormValues {
  currency: string;
  amount: number;
  reason: string;
}

const PaymentsUp: React.FC = () => {
  const { t } = useTranslation("payments");

  const [operation, setOperation] = useState<String>(OPERATIONS.credit);
  const [fromAccounts, setFromAccounts] = useState<AccountResponse[]>([]);
  const [fromAccount, setFromAccount] = useState<AccountResponse>();
  const [searchFrom, setSearchFrom] = useState("");

  const [isFromSelected, setIsFromSelected] = useState(false);

  const [currency, setCurrency] = useState("");
  const [balance, setBalance] = useState({} as Balance | undefined);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultValues = {
    from_account_type: "",
    from_account_status: "enabled",
    from_Search: "",
    amount: 0,
    reason: "",
    houseAccountAffected: false,
  };

  const fromAccountDetails = {
    id: fromAccount?.account_id || "n/a",
    account_name: fromAccount?.account_name || "n/a",
    account_holder: fromAccount?.account_holder || "n/a",
    quid_reference: fromAccount?.quid_reference || "n/a",
    account_type: fromAccount?.account_type || "n/a",
    street: fromAccount?.street || "n/a",
    city: fromAccount?.city || "n/a",
    postal_code: fromAccount?.postal_code || "n/a",
    country: fromAccount?.country || "n/a",
    state_or_province: fromAccount?.state_or_province || "n/a",
    account_status: fromAccount?.status || "n/a",
  };

  const transactionDetails = {
    current_amount:
      `${MoneyFormatter(
        balance?.current_amount || 0,
        currency as DineroFactory.Currency
      )}` || "n/a",
    available_amount:
      `${MoneyFormatter(
        balance?.available_amount || 0,
        currency as DineroFactory.Currency
      )}` || "n/a",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, watch, reset } = methods;

  const accountTypes = useSelector(getAccountTypes);
  const accountStatuses = useSelector(getAccountStatus);
  const fromAccountType = watch("from_account_type");
  const fromAccountStatus = watch("from_account_status");

  const onResetForm = () => {
    reset(defaultValues);
    setFromAccount(undefined);
    setSearchFrom("");
    setFromAccounts([]);
    setIsFromSelected(false);
    setOperation(OPERATIONS.credit);
  };

  useEffect(() => {
    setBalance(
      fromAccount?.balances?.filter(
        (balance: Balance) => balance?.currency === currency
      )[0]
    );
  }, [currency]);

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        if (fromAccountStatus !== "" && fromAccountType !== undefined) {
          const { accounts } = await fetchUserAccount({
            type: fromAccountType,
            status: fromAccountStatus,
            ...(searchFrom && { keyword: searchFrom }),
            size: TABLE_PAGE_SIZE,
          });
          setFromAccounts(accounts);
        }
      } catch (err: any) {
        handleFailure(err);
      }
    };

    void getAllUsers();
  }, [searchFrom, fromAccountType, fromAccountStatus]);

  useEffect(() => {
    if (fromAccount) {
      setIsFromSelected(true);
    }
  }, [fromAccount]);

  const onFormSubmit = async (values: FormValues) => {
    try {
      const amount = values?.amount?.toString()?.trim()?.replace("-", "");

      await createUpTransaction({
        balance_id: values.currency,
        reason: values.reason,
        amount: operation === OPERATIONS.debit ? `-${amount}` : amount,
        houseAccountAudited: true,
      });
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("payment__success__message"),
          type: "success",
        })
      );
      history.push("/payments/outgoing");
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    operation: string | null
  ) => {
    if (operation !== null) {
      setOperation(operation);
    }
  };

  return (
    <MainTemplate>
      <QuidTitle>{t("balance_adjustments__page__title")}</QuidTitle>
      <ToggleButtonGroup
        exclusive
        value={operation}
        aria-label="Operation"
        onChange={handleToggleChange}
      >
        <ToggleButton value="credit">
          {t("toggle_button__operation__credit")}
        </ToggleButton>
        <ToggleButton value="debit">
          {t("toggle_button__operation__debit")}
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormProvider {...methods}>
            <div className={classes.containerInner}>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {operation === OPERATIONS.credit
                    ? t("internal__transfer__head__credit_to__label")
                    : t("internal__transfer__head__debit_to__label")}
                </QuidTitle>
                {!isFromSelected === true && (
                  <div>
                    <QuidTextField
                      name="from_account_type"
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                      }}
                      rules={{
                        required: t("from__account__type__required") as string,
                      }}
                      defaultValues={defaultValues}
                      label={
                        operation === OPERATIONS.credit
                          ? t("to__credit__type")
                          : t("to__debit__type")
                      }
                    >
                      <MenuItem value="">{t("from__account__type")}</MenuItem>
                      {accountTypes?.map((option: AccountDataset, index) => (
                        <MenuItem
                          key={`${option}-${index}`}
                          value={option.code}
                        >
                          {option.text}
                        </MenuItem>
                      ))}
                    </QuidTextField>
                    {process.env.REACT_APP_KEYCLOAK_REALM === "toonie" &&
                      fromAccountType && (
                        <QuidTextField
                          name="from_account_status"
                          textFieldProps={{
                            select: true,
                            fullWidth: true,
                          }}
                          rules={{
                            required: t(
                              "from__account__status__required"
                            ) as string,
                          }}
                          defaultValues={defaultValues}
                          label={t("from__account__status")}
                        >
                          {accountStatuses?.map(
                            (option: AccountDataset, index) => (
                              <MenuItem
                                key={`${option}-${index}`}
                                value={option.code}
                              >
                                {option.text}
                              </MenuItem>
                            )
                          )}
                        </QuidTextField>
                      )}
                    <TextField
                      label={t("filter__search")}
                      name="from_search"
                      variant="outlined"
                      fullWidth
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setSearchFrom(e.target.value)
                      }
                      value={searchFrom}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div className={classes.searchBox}>
                              <SearchIcon color="#fff" />
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {fromAccounts?.map(
                      (account: AccountResponse, index: number) => (
                        <div
                          key={index}
                          onClick={() => {
                            setFromAccount(account);
                          }}
                          className={classes.accountDetailsWrapper}
                        >
                          <div>
                            <Typography variant="body1">
                              {account.account_name} - {account.account_id}
                              <br />
                              <small>{account.account_holder}</small>
                            </Typography>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
                {fromAccount && (
                  <DetailsBlock label="" toPrint={fromAccountDetails} />
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__info")}
                </QuidTitle>
                {fromAccount && (
                  <div>
                    <QuidTextField
                      rules={{
                        required: t("currency__required") as string,
                      }}
                      name="currency"
                      defaultValues={defaultValues}
                      label={t("internal__transfer__currency__placeholder")}
                      textFieldProps={{ select: true, fullWidth: true }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setCurrency(e.target.value)
                      }
                    >
                      {fromAccount?.balances?.map(
                        (frombalance: Balance, index: number) => {
                          return (
                            <MenuItem
                              key={`${frombalance.currency}-${index}`}
                              value={frombalance.balance_id}
                            >
                              {frombalance?.currency}
                            </MenuItem>
                          );
                        }
                      )}
                    </QuidTextField>
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      name="amount"
                      rules={{
                        required: t(
                          "internal__transfer__form__amount__required"
                        ) as string,
                      }}
                      onKeyDown={(e) =>
                        ["+", "-"].includes(e.key) && e.preventDefault()
                      }
                      label={t("internal__transfer__form__amount__label")}
                      defaultValues={defaultValues}
                      helperText={t("internal__transfer__form__positive_value")}
                    />
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      rules={{
                        required: t("reason__required") as string,
                      }}
                      name="reason"
                      label={t("internal__transfer__form__reference__label")}
                      defaultValues={defaultValues}
                    />
                    <QuidFormControl
                      formControl={<Checkbox color="primary" />}
                      name={`checks`}
                      defaultValues={defaultValues}
                      label={t("checkbox__houseAccountAffected")}
                    />
                    {balance && (
                      <div>
                        <DetailsBlock label="" toPrint={transactionDetails} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.containerInnerButtons}>
              <Button variant="contained" color="primary" type="submit">
                {t("internal__transfer__create__button")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={onResetForm}
              >
                {t("internal__transfer__reset__button")}
              </Button>
            </div>
          </FormProvider>
        </form>
      </div>
    </MainTemplate>
  );
};

export default PaymentsUp;
