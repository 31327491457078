import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ApproveRejectButtonsProps {
  onAccept: () => void;
  onReject: () => void;
  disabled?: boolean;
  disableAccept?: boolean;
  disableReject?: boolean;
  labelAcceptButton?: string;
  labelRejectButton?: string;
}

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

const ApproveRejectButtons: React.FC<ApproveRejectButtonsProps> = ({
  onAccept,
  onReject,
  disabled,
  disableReject,
  disableAccept,
  labelAcceptButton,
  labelRejectButton,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("validations");
  return (
    <Grid item xs={12} className={classes.buttonsWrapper}>
      <Button
        disabled={disabled || disableReject}
        variant="contained"
        color="primary"
        className="actions actions-reject"
        onClick={onReject}
      >
        {labelRejectButton ? labelRejectButton : t("reject")}
      </Button>
      <Button
        disabled={disabled || disableAccept}
        variant="contained"
        color="primary"
        className="actions actions-approve"
        onClick={onAccept}
      >
        {labelAcceptButton ? labelAcceptButton : t("approve")}
      </Button>
    </Grid>
  );
};

export default ApproveRejectButtons;
