import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ApproveRejectBox from "../../atoms/ApproveRejectBox";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { formatDate } from "utils";
import { AccountInfo } from "entities/accounts/Account";
import VerificationTemplate from "templates/VerificationTemplate";
import { useSelector } from "react-redux";
import { getPartiesCountries } from "store/reducers/app.reducer";
import AttachmentWithoutPreview from "components/molecules/verifications/AttachmentWithoutPreview";
import jwt_decode from "jwt-decode";
import { acceptTask, rejectTask } from "api/tasks";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { FinancialData } from "entities/clients/FinancialEntity";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { Grid } from "@material-ui/core";
import { handleFailure } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

const individualCheckAccountDetailsDirectCheck = {
  ALL_IS_OK: false,
};

export interface IndividualDetails {
  [key: string]: string;
}

export interface QuidAccountInformationInterface {
  quidReference: string;
  currency: string;
  account_type: string;
}

const IndividualCheckAccountDetailsDirectComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();

  const customerDetail = task?.variables?.customer;
  const customerAddressDetail = task?.variables?.address;
  const taskAccountInfo = task?.variables?.accountInfo;
  const taskFinancial = task?.variables?.financial;
  const taskDocumentAccountUsage = task?.documents.find(
    (d) => d.type === "ACCOUNT_USAGE"
  );
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  if (!task) {
    return null;
  }

  const customerToPrint: IndividualDetails = {
    userId: customerDetail?.id,
    name: customerDetail?.name,
    surname: customerDetail?.surname,
    dateOfBirth: formatDate(customerDetail?.birthDate),
    countryOfBirth: getCountry(customerDetail?.countryOfBirth),
    nationality: getCountry(customerDetail?.nationality),
    "CONTACT INFORMATIONS": "",
    phone: `${customerDetail?.prefix} ${customerDetail?.mobileNumber}`,
    email: customerDetail?.email,
    "OTHER INFORMATIONS": "",
    mobileNumberVerified: customerDetail?.mobileNumberVerified ? "Yes" : "No",
    emailVerified: customerDetail?.emailVerified ? "Yes" : "No",
    ADDRESS: "",
    addressLine1: customerAddressDetail?.addressLine1,
    addressLine2: customerAddressDetail?.addressLine2,
    city: customerAddressDetail?.city,
    state: customerAddressDetail?.state || "",
    country: getCountry(customerAddressDetail?.country) || "",
    postalCode: customerAddressDetail?.postalCode,
  };

  const taskFinancialToPrint: Partial<FinancialData> = {
    fundsSourceValue: taskFinancial?.fundsSourceValue || "n/a",
    expectedAnnualIncoming: taskFinancial?.expectedAnnualIncoming || "n/a",
    employmentSectorType: taskFinancial?.employmentSectorType || "n/a",
    employmentSectorValue: taskFinancial?.employmentSectorValue || "n/a",
    employmentStatusType: taskFinancial?.employmentStatusType || "n/a",
    employmentStatusValue: taskFinancial?.employmentStatusValue || "n/a",
  };

  const taskAccountInfoToPrint: Partial<AccountInfo> = {
    accountPurpose: taskAccountInfo?.accountPurpose || "n/a",
    paymentOrigin: getCountry(taskAccountInfo?.paymentOrigin) || "",
    paymentDestination: getCountry(taskAccountInfo?.paymentDestination) || "",
    paymentNumberIncomingPerMonth:
      taskAccountInfo?.paymentNumberIncomingPerMonth || "n/a",
    paymentVolumeIncomingPerMonth:
      taskAccountInfo?.paymentVolumeIncomingPerMonth || "n/a",
    paymentNumberOutcomingPerMonth:
      taskAccountInfo?.paymentNumberOutcomingPerMonth || "n/a",
    paymentVolumeOutcomingPerMonth:
      taskAccountInfo?.paymentVolumeOutcomingPerMonth || "n/a",
  };

  const handleApproveTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      task={task}
      columns={columns}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {customerToPrint && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={customerToPrint}
              label="individual__details__title"
            />
          </div>
        )}
        <div className={classes.detailsWrapperThird}>
          {taskFinancialToPrint && (
            <DetailsBlock
              toPrint={taskFinancialToPrint}
              label="financial__details__title"
            />
          )}
          <br />
          {taskAccountInfoToPrint && (
            <DetailsBlock
              toPrint={taskAccountInfoToPrint}
              label="account__details__title"
            />
          )}
        </div>
        <div className={classes.detailsWrapperThird}>
          {taskDocumentAccountUsage && (
            <div>
              <DetailsBlock label="account__supporting__document" />
              <AttachmentWithoutPreview
                attachment={taskDocumentAccountUsage}
                downloadDoc={(params) =>
                  isHistory === undefined
                    ? fetchDocument({ ...params, taskId, taskKey })
                    : fetchHistoricalDocument({ ...params, taskId, taskKey })
                }
              />
            </div>
          )}
        </div>
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            checks={individualCheckAccountDetailsDirectCheck}
            task={task}
            accept={(request) => handleApproveTask(request)}
            reject={(request) => handleRejectTask(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task?.checks}
          status={task?.result?.status}
          reason={task?.result?.reason}
          description={task?.result?.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default IndividualCheckAccountDetailsDirectComp;
