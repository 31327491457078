import { RowId } from "@material-ui/data-grid";
import { Pagination } from "entities/accounts/Pagination";
import {
  CreateAddressParams,
  PartyAddress,
} from "entities/clients/AddressEntity";
import { AssociateCompaniesToCustomer } from "entities/clients/CompanyEntity";
import {
  FilePayload,
  CustomerResponse,
  EnhancedAddress,
  CustomerAddressResponse,
  CustomerFinancialResponse,
  CustomerHistoryResponse,
  UpdateCustomerParams,
  CustomerDetailsForm,
  CustomerListParams,
  CustomerListResponse,
  CustomerDetails,
  CustomerDocumentsResponse,
  FinancialParams,
  RejectCustomerDocumentParams,
  AccountInfoResponse,
  AcceptCustomerDocumentParams,
  CustomerHistoryParams,
  DownloadCustomersParams,
  CustomersResponse,
} from "entities/clients/CustomerBackofficeEntity";
import { CustomerDocument } from "entities/clients/DocumentEntity";
import { FinancialData } from "entities/clients/FinancialEntity";
import { Profile, ProfileResponse } from "entities/clients/ProfileEntity";
import { handleApiClientError } from "utils";
import { flattenAddresses } from "utils/addresses";
import { apiClient } from "@portit/core/utils/api/apiClient";
import {
  Balance,
  FetchBalancesParams,
  RawBalance,
} from "entities/accounts/Balance";
import { getCustomerWalletsByPartyId } from "./accounts";
import { ResponseTasks } from "entities/tasks/TaskEntity";

export async function getCustomerProfilesByCustomerId(
  customerId: number
): Promise<Profile[]> {
  try {
    const res = await apiClient.get<ProfileResponse>(
      `/parties/v1/customers/${customerId}/profiles`,
      "company"
    );
    const { profiles }: { profiles: Profile[] } = res;
    return profiles;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerTasksByCustomerId(
  customerId: number
): Promise<ResponseTasks> {
  try {
    const res = await apiClient.get<ResponseTasks>(
      `/parties/v1/customers/${customerId}/kyc`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerDetailsByCustomerId(
  id: string | number
): Promise<CustomersResponse> {
  try {
    const res = await apiClient.get<CustomersResponse>(
      `/parties/v1/customers/${id}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function acceptDocumentCustomer(
  customerId: string,
  documentId: string
): Promise<any> {
  try {
    await apiClient.put<void>(
      `/parties/v1/customers/${customerId}/documents/${documentId}/accept`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function rejectDocumentCustomer(
  customerId: string,
  documentId: string
): Promise<any> {
  try {
    await apiClient.put<void>(
      `/parties/v1/customers/${customerId}/documents/${documentId}/tocustomer`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function acceptCustomerDocument(
  params: AcceptCustomerDocumentParams
): Promise<void> {
  try {
    await apiClient.put(
      `/parties/v1/customers/${params.customerId}/documents/${params.documentId}/accept`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createCustomerAddress(
  params: CreateAddressParams
): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/parties/v1/customers/${params.id}/addresses`,
      "company",
      { address: params.address }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadCustomerDocumentById(
  customerId: number,
  documentId: number
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/parties/v1/customers/${customerId}/documents/${documentId}/download`,
      "company",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerByCustomerId(
  id: number
): Promise<CustomerResponse> {
  try {
    const res = await apiClient.get<CustomerResponse>(
      `/parties/v1/customers/${id}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerAddresses(
  id: number
): Promise<EnhancedAddress[]> {
  try {
    const res = await apiClient.get<CustomerAddressResponse>(
      `/parties/v1/customers/${id}/addresses`,
      "company"
    );

    const { addresses }: { addresses: PartyAddress[] } = res;
    return flattenAddresses(addresses);
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerFinancialByCustomerId(
  id: number
): Promise<FinancialData> {
  try {
    const res = await apiClient.get<CustomerFinancialResponse>(
      `/parties/v1/customers/${id}/financial`,
      "company"
    );

    const { financialData }: { financialData: FinancialData } = res;
    return financialData;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerHistoryByCustomerId(
  params: CustomerHistoryParams
): Promise<CustomerHistoryResponse> {
  try {
    const res: CustomerHistoryResponse = await apiClient.get(
      `/parties/v1/customers/${params.customerId}/history`,
      "company",
      {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateCustomerDetails(
  params: UpdateCustomerParams
): Promise<{ customer: CustomerDetailsForm }> {
  try {
    const res = await apiClient.put<{ customer: CustomerDetailsForm }>(
      `/parties/v1/customers/${params.id}`,
      "company",
      {
        customer: params.body,
      }
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCustomerList(
  params: CustomerListParams
): Promise<CustomerListResponse> {
  try {
    const res = await apiClient.get<CustomerListResponse>(
      `/parties/v1/customers/`,
      "company",
      {
        pageNo: params.pageNo,
        type: params.type,
        pageSize: params.pageSize,
        ...(params.keyword && { keyword: params.keyword }),
        ...(params.from && { from: params.from }),
        ...(params.to && { to: params.to }),
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function lockUser(id: number): Promise<CustomerDetails> {
  try {
    const res = await apiClient.post<CustomerDetails>(
      `/parties/v1/customers/${id}/lock`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function unlockUser(id: number): Promise<CustomerDetails> {
  try {
    const res = await apiClient.post<CustomerDetails>(
      `/parties/v1/customers/${id}/unlock`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerDocumentsByCustomerId(
  id: number
): Promise<CustomerDocument[]> {
  try {
    const res: CustomerDocumentsResponse = await apiClient.get(
      `/parties/v1/customers/${id}/documents`,
      "company"
    );
    const { documents }: { documents: CustomerDocument[] } = res;
    return documents;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function uploadCustomerDocumentById(
  id: number,
  body: { document: File },
  type: string,
  subType: string
): Promise<{
  document: CustomerDocument;
}> {
  try {
    const res = await apiClient.post<{
      document: CustomerDocument;
    }>(
      `/parties/v1/customers/${id}/documents?type=${type.toString()}&sub_type=${subType.toString()}`,
      "company",
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function updateCustomerFinancial(
  financialData: FinancialParams
): Promise<FinancialData> {
  try {
    const res: CustomerFinancialResponse = await apiClient.post(
      `/parties/v1/customers/${financialData.customerId}/financial`,
      "company",
      { financialData }
    );
    const { financialData: result }: { financialData: FinancialData } = res;
    return result;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function rejectCustomerDocument(
  params: RejectCustomerDocumentParams
): Promise<void> {
  try {
    await apiClient.put(
      `/parties/v1/customers/${params.customerId}/documents/${params.documentId}/reject`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function sendSmsVerificationCode(
  customerId: number
): Promise<void> {
  try {
    await apiClient.put(
      `/parties/v1/customers/${customerId}/mobile/reset`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function sendEmailVerificationCode(
  username: string
): Promise<void> {
  try {
    await apiClient.put(
      `/parties/v1/customers/email/send/${username}`,
      "company"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function sendWelcomeEmail(userId: number | string): Promise<void> {
  try {
    await apiClient.post(`/parties/v1/customers/mail/${userId}`, "company");
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerAccounts(
  customerId: number
): Promise<AccountInfoResponse> {
  try {
    const res = await apiClient.get<AccountInfoResponse>(
      `/parties/v1/customers/${customerId}/account`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchCustomerWallets(
  params: FetchBalancesParams
): Promise<{ pagination: Pagination; balances: Balance[] }> {
  try {
    const { pagination, balances } = await getCustomerWalletsByPartyId(params);

    const balancesWithId = balances.map((balance: RawBalance) => ({
      id: balance.balance_id,
      ...balance,
    }));
    return { pagination, balances: balancesWithId };
  } catch (e) {
    throw e;
  }
}

export async function associateCostumerOnBehalf(
  profileId: RowId
): Promise<AccountInfoResponse> {
  try {
    const res = await apiClient.post<AccountInfoResponse>(
      `/parties/v1/customers/profiles/onbehalf/${profileId}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function associateCompanyToCustomer(
  customerId: number
): Promise<AssociateCompaniesToCustomer> {
  try {
    const res = await apiClient.get<AssociateCompaniesToCustomer>(
      `/parties/v1/customers/${customerId}/companies`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadCustomersCSVDocument(
  params: DownloadCustomersParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/parties/v1/customers/csv/customers`,
      "company",
      {
        ...(params.keyword && { keyword: params.keyword }),
        ...(params.from && { from: params.from }),
        ...(params.to && { to: params.to }),
        type: "INDIVIDUAL",
      },
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
