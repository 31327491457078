import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ColDef } from "@material-ui/data-grid";
import { useRouteMatch } from "react-router";
import { useSelector } from "react-redux";
import { getPartiesCountries } from "store/reducers/app.reducer";
import ApproveRejectBox from "../../atoms/ApproveRejectBox";
import { formatDate } from "utils";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import VerificationTemplate from "templates/VerificationTemplate";
import GetOrUpdatePersonDocument from "../../molecules/verifications/GetOrUpdatePersonDocument";
import jwt_decode from "jwt-decode";
import { acceptTask, rejectTask } from "api/tasks";
import { Address } from "entities/clients/AddressEntity";
import { GenericObject } from "entities/tasks/Identity";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { handleFailure } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";

const useStyles = makeStyles(() => ({
  bottomBoxContainer: {
    marginTop: 20,
  },
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 50px 0 50px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    marginBottom: 50,
  },
  detailsWrapperThird: {
    width: "33%",
    marginBottom: 50,
    marginRight: 15,
    marginLeft: 15,
  },
}));

const CheckCompanyDetailsComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const [checkCompanyDetailsChecks, setCheckCompanyDetailsChecks] = useState(
    {}
  );
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();

  const companyDetails = task?.variables?.companyDetailsResponse;
  const taxRegistrationGst = task?.variables?.taxRegistrationGst;
  const taxRegistrationIrd = task?.variables?.taxRegistrationIrd;
  const companyDirectors = task?.variables?.companyDirectors;
  const companyShareholders = task?.variables?.companyShareholders;
  const companyDirectorsDocuments = task?.variables?.companyDirectorsDocuments;
  const companyShareholdersDocuments =
    task?.variables?.companyShareholdersDocuments;
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const companyShareholdersOrganization = task?.variables?.companyShareholders.filter(
    (shareholderRow: GenericObject) => {
      return shareholderRow?.shareholderType === "Organisation";
    }
  );
  const companyShareholdersPerson = task?.variables?.companyShareholders.filter(
    (shareholderRow: GenericObject) => {
      return shareholderRow?.shareholderType === "Person";
    }
  );
  const companyCommunicationAddress =
    task?.variables?.companyCommunicationAddress;
  const companyRegisteredAddress = task?.variables?.companyRegisteredAddress;
  const taxCommunicationAddress = task?.variables?.taxCommunicationAddress;
  const companyServiceAddress = task?.variables?.companyServiceAddress;
  const taxPremiseAddress = task?.variables?.taxPremiseAddress;
  const companyShareholderAddress = task?.variables?.companyShareholdersAddresses?.addresses.find(
    (address: Address) =>
      companyShareholders.some(
        (shareholder: GenericObject) =>
          shareholder?.contacts?.physicalOrPostalAddresses === address?.id
      )
  );
  const companyDirectorsAddress = task?.variables?.companyDirectorsAddresses?.addresses.find(
    (address: Address) =>
      companyDirectors.some(
        (director: GenericObject) =>
          director?.contact?.physicalOrPostalAddresses === address?.id
      )
  );

  useEffect(() => {
    setCheckCompanyDetailsChecks({
      ALL_INFORMATIONS_ARE_CORRECT: false,
    });
    const companyDirectorsAustralian = task?.variables?.companyDirectors.filter(
      (d: any) =>
        d?.directorSpecific?.asicDirectorCompanyInfo
          ?.australianCompanyNumber !== null &&
        d?.directorSpecific?.asicDirectorCompanyInfo
          ?.australianCompanyNumber !== undefined
    );

    if (companyDirectorsAustralian?.length) {
      setCheckCompanyDetailsChecks({
        ALL_INFORMATIONS_ARE_CORRECT: false,
        DIRECTORS_AUSTRALIAN_CHECKED: false,
      });
    }
  }, [task]);

  if (!task) {
    return null;
  }

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const corporateDetailsToPrint = {
    companyId: companyDetails?.companyId,
    companyName: companyDetails?.companyName,
    companyUuid: companyDetails?.companyUuid,
    email: companyDetails?.email,
    annual__return__filing__month:
      companyDetails?.annual__return__filing__month,
  };

  const taxRegistrationGstToPrint = {
    taxRegistrationGstId: taxRegistrationGst?.taxRegistrationGstId,
    accountingMethod: taxRegistrationGst?.accountingMethod,
    filingFrequency: taxRegistrationGst?.filingFrequency,
    questionsDifficulty: taxRegistrationGst?.questionsDifficulty,
    contactName: `${taxRegistrationGst?.contactName?.lastName}
     ${taxRegistrationGst?.contactName?.middleNames ?? ""}
     ${taxRegistrationGst?.contactName?.firstName}`,
    contactPhone: `${taxRegistrationGst?.contactPhone?.countryCode}
     ${taxRegistrationGst?.contactPhone?.areaCode}
     ${taxRegistrationGst?.contactPhone?.phoneNumber}`,
    refundBankAccount: `${taxRegistrationGst?.refundBankAccount?.account}
     ${taxRegistrationGst?.refundBankAccount?.branch}
     ${taxRegistrationGst?.refundBankAccount?.bank}
     ${taxRegistrationGst?.refundBankAccount?.suffix}`,
    exemptGst: taxRegistrationGst?.exemptGs0t ? "Yes" : "No",
    exportingGoods: taxRegistrationGst?.exportingGoods ? "Yes" : "No",
    importingGoods: taxRegistrationGst?.importingGoods ? "Yes" : "No",
    workedWithGst: taxRegistrationGst?.workedWithGst ? "Yes" : "No",
    exceeds60K: taxRegistrationGst?.exceeds60K ? "Yes" : "No",
  };

  const taxRegistrationIrdToPrint = {
    taxRegistrationIrdId: taxRegistrationIrd?.taxRegistrationIrdId,
    bicCode: taxRegistrationIrd?.bicCode,
    contactName: `${taxRegistrationIrd?.companyContactTaxRegistration?.lastName}
     ${taxRegistrationIrd?.companyContactTaxRegistration?.middleNames ?? ""}
     ${taxRegistrationIrd?.companyContactTaxRegistration?.firstName}`,
    contactPhone: `${taxRegistrationIrd?.companyContactTaxRegistration?.contactPhone?.countryCode}
      ${taxRegistrationIrd?.companyContactTaxRegistration?.contactPhone?.areaCode}
      ${taxRegistrationIrd?.companyContactTaxRegistration?.contactPhone?.phoneNumber}`,
    companyContactEmail: taxRegistrationIrd?.companyContact?.email,
    companyContactPhone: `${taxRegistrationIrd?.companyContact?.phoneContact?.countryCode}
      ${taxRegistrationIrd?.companyContact?.phoneContact?.areaCode}
      ${taxRegistrationIrd?.companyContact?.phoneContact?.phoneNumber}`,
    overseasShareholding: taxRegistrationIrd?.overseasShareholding
      ? "Yes"
      : "No",
    taxNonActive: taxRegistrationIrd?.taxNonActive ? "Yes" : "No",
    offeringFringeBenefits: taxRegistrationIrd?.offeringFringeBenefits
      ? "Yes"
      : "No",
  };

  const companyCommunicationAddressToPrint = {
    addressLine1: companyCommunicationAddress?.addressLine1,
    addressLine2: companyCommunicationAddress?.addressLine2,
    city: companyCommunicationAddress?.city,
    postalCode: companyCommunicationAddress?.postalCode,
    state: companyCommunicationAddress?.state,
    country: getCountry(companyCommunicationAddress?.country),
  };

  const companyRegisteredAddressToPrint = {
    addressLine1: companyRegisteredAddress?.addressLine1,
    addressLine2: companyRegisteredAddress?.addressLine2,
    city: companyRegisteredAddress?.city,
    postalCode: companyRegisteredAddress?.postalCode,
    state: companyRegisteredAddress?.state,
    country: getCountry(companyRegisteredAddress?.country),
  };

  const taxCommunicationAddressToPrint = {
    addressLine1: taxCommunicationAddress?.addressLine1,
    addressLine2: taxCommunicationAddress?.addressLine2,
    city: taxCommunicationAddress?.city,
    postalCode: taxCommunicationAddress?.postalCode,
    state: taxCommunicationAddress?.state,
    country: getCountry(taxCommunicationAddress?.country),
  };

  const companyServiceAddressToPrint = {
    addressLine1: companyServiceAddress?.addressLine1,
    addressLine2: companyServiceAddress?.addressLine2,
    city: companyServiceAddress?.city,
    postalCode: companyServiceAddress?.postalCode,
    state: companyServiceAddress?.state,
    country: getCountry(companyServiceAddress?.country),
  };

  const taxPremiseAddressToPrint = {
    addressLine1: taxPremiseAddress?.addressLine1,
    addressLine2: taxPremiseAddress?.addressLine2,
    city: taxPremiseAddress?.city,
    postalCode: taxPremiseAddress?.postalCode,
    state: taxPremiseAddress?.state,
    country: getCountry(taxPremiseAddress?.country),
  };

  const companyDirectorsToPrint = (companyDirector: GenericObject) => ({
    directorId: companyDirector?.directorId,
    documentId: companyDirector?.documentId,
    firstName: companyDirector?.personInRole?.name?.firstName,
    lastName: companyDirector?.personInRole?.name?.lastName,
    dateOfBirth: formatDate(
      companyDirector?.personInRole?.birthInfo?.dateOfBirth
    ),
    countryOfBirth: getCountry(
      companyDirector?.personInRole?.birthInfo?.countryOfBirth
    ),
    addressLine1: companyDirectorsAddress?.addressLine1,
    addressLine2: companyDirectorsAddress?.addressLine2,
    city: companyDirectorsAddress?.city,
    postalCode: companyDirectorsAddress?.postalCode,
    state: companyDirectorsAddress?.state,
    country: companyDirectorsAddress?.country,
    directorSpecific: JSON.stringify(companyDirector?.directorSpecific),
  });

  const companyShareholdersPersonToPrint = (
    companyShareholder: GenericObject
  ) => ({
    shareholderId: companyShareholder?.shareholderId,
    firstName: companyShareholder?.personShareholder?.name?.firstName,
    lastName: companyShareholder?.personShareholder?.name?.lastName,
    dateOfBirth: formatDate(
      companyShareholder?.personShareholder?.birthInfo?.dateOfBirth
    ),
    countryOfBirth: getCountry(
      companyShareholder?.personShareholder?.birthInfo?.countryOfBirth
    ),
    addressLine1: companyShareholderAddress?.addressLine1,
    addressLine2: companyShareholderAddress?.addressLine2,
    city: companyShareholderAddress?.city,
    postalCode: companyShareholderAddress?.postalCode,
    state: companyShareholderAddress?.state,
    country: companyShareholderAddress?.country,
    type: companyShareholder?.shareholderType,
    numberOfShares: companyShareholder?.numberOfShares,
    contactPhone: `${companyShareholder?.contacts?.phoneContacts?.countryCode}
     ${companyShareholder?.contacts?.phoneContacts?.areaCode}
     ${companyShareholder?.contacts?.phoneContacts?.phoneNumber}`,
    contactEmail: companyShareholder?.contacts?.emailAddresses?.emailAddress,
  });

  const companyShareholdersOrganizationToPrint = (
    companyShareholder: GenericObject
  ) => ({
    shareholderId: companyShareholder?.shareholderId,
    registrationNumber:
      companyShareholder?.organisationShareholder?.registrationNumber,
    name: companyShareholder?.organisationShareholder?.name,
    entityType: companyShareholder?.organisationShareholder?.entityType,
    nzbn: companyShareholder?.organisationShareholder?.nzbn,
    countryOfOrigin: getCountry(
      companyShareholder?.organisationShareholder?.countryOfOrigin
    ),
    type: companyShareholder?.shareholderType,
    numberOfShares: companyShareholder?.numberOfShares,
    contactPhone: `${companyShareholder?.contacts?.phoneContacts?.countryCode}
     ${companyShareholder?.contacts?.phoneContacts?.areaCode}
     ${companyShareholder?.contacts?.phoneContacts?.phoneNumber}`,
    contactEmail: companyShareholder?.contacts?.emailAddresses?.emailAddress,
  });

  const handleApproveTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        <div className={classes.detailsWrapperQuarter}>
          <DetailsBlock
            toPrint={corporateDetailsToPrint}
            label="corporate__details__title"
          />
        </div>
        {taxRegistrationGst && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={taxRegistrationGstToPrint}
              label="tax__registration__gst__title"
            />
          </div>
        )}
        {taxRegistrationIrd && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={taxRegistrationIrdToPrint}
              label="tax__registration__gst__title"
            />
          </div>
        )}
      </div>
      <div className={classes.topContainer}>
        {companyCommunicationAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={companyCommunicationAddressToPrint}
              label="company__communication__address__title"
            />
          </div>
        )}
        {companyRegisteredAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={companyRegisteredAddressToPrint}
              label="company__registration__address__title"
            />
          </div>
        )}
        {companyServiceAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={companyServiceAddressToPrint}
              label="company__service__address__title"
            />
          </div>
        )}
      </div>
      <div className={classes.topContainer}>
        {taxCommunicationAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={taxCommunicationAddressToPrint}
              label="tax__communication__address__title"
            />
          </div>
        )}
        {taxPremiseAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={taxPremiseAddressToPrint}
              label="tax__premise__address__title"
            />
          </div>
        )}
      </div>
      <div className={classes.topContainer}>
        {companyDirectors?.map((director: GenericObject, index: number) => (
          <div
            className={classes.detailsWrapperThird}
            key={`cmp-director-${index}`}
          >
            <DetailsBlock
              toPrint={companyDirectorsToPrint(director)}
              label="company__director__title"
              key={`cmp-director-${index}`}
            />
            <GetOrUpdatePersonDocument
              label="directorConsent"
              refetch={refetch}
              taskId={taskId}
              taskKey={taskKey}
              companyId={companyDetails?.companyId}
              personType={"directors"}
              showDetail={false}
              personId={director.directorId}
              doc={companyDirectorsDocuments?.find(
                (d: any) =>
                  d.foreingIds.DIRECTOR_ID === director.directorId.toString()
              )}
              canReplace={isHistory === undefined && isAssignedToUser}
              isHistory={isHistory}
            />
          </div>
        ))}
        {companyShareholdersPerson?.map((shareholder: any, index: number) => (
          <div
            className={classes.detailsWrapperThird}
            key={`cmp-shareholder-${index}`}
          >
            <DetailsBlock
              toPrint={companyShareholdersPersonToPrint(shareholder)}
              label="company__shareholder__title"
            />
            <GetOrUpdatePersonDocument
              label="shareholderConsent"
              refetch={refetch}
              taskId={taskId}
              taskKey={taskKey}
              companyId={companyDetails?.companyId}
              personType={"shareholders"}
              showDetail={false}
              personId={shareholder.shareholderId}
              doc={companyShareholdersDocuments?.find(
                (d: any) =>
                  d.foreingIds.SHAREHOLDER_ID ===
                  shareholder?.shareholderId?.toString()
              )}
              canReplace={isHistory === undefined && isAssignedToUser}
              isHistory={isHistory}
            />
          </div>
        ))}
        {companyShareholdersOrganization?.map(
          (shareholder: any, index: number) => (
            <div
              className={classes.detailsWrapperThird}
              key={`cmp-shareholder-${index}`}
            >
              <DetailsBlock
                toPrint={companyShareholdersOrganizationToPrint(shareholder)}
                label="company__shareholder__title"
              />
              <GetOrUpdatePersonDocument
                label="shareholderConsent"
                refetch={refetch}
                taskId={taskId}
                taskKey={taskKey}
                companyId={companyDetails?.companyId}
                personType={"shareholders"}
                showDetail={false}
                personId={shareholder.shareholderId}
                doc={companyShareholdersDocuments.find(
                  (d: any) => d.id === shareholder.documentId.toString()
                )}
                canReplace={isHistory === undefined && isAssignedToUser}
                isHistory={isHistory}
              />
            </div>
          )
        )}
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container className={classes.bottomBoxContainer}>
          <ApproveRejectBox
            checks={checkCompanyDetailsChecks}
            task={task}
            accept={(request) => handleApproveTask(request)}
            reject={(request) => handleRejectTask(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task?.checks}
          status={task?.result?.status}
          reason={task?.result?.reason}
          description={task?.result?.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default CheckCompanyDetailsComp;
