import {
  uploadCustomerDocumentById,
  getCustomerByCustomerId,
  getCustomerProfilesByCustomerId,
  getCustomerDocumentsByCustomerId,
  getCustomerFinancialByCustomerId,
  getCustomerAccounts,
  getCustomerAddresses,
  downloadCustomerDocumentById,
  downloadCustomersCSVDocument,
} from "api/customers";
import { getCustomerProductsByPartyId } from "api/product";
import { format } from "date-fns";
import {
  UploadCustomerDocumentParams,
  DownloadCustomerDocumentParams,
  FilePayload,
  CustomerDetailsParams,
  CustomerDetails,
  CustomerResponse,
  EnhancedAddress,
  AccountInfoResponse,
  DownloadCustomersParams,
} from "entities/clients/CustomerBackofficeEntity";
import { CustomerDocument } from "entities/clients/DocumentEntity";
import { FinancialData } from "entities/clients/FinancialEntity";
import { Profile } from "entities/clients/ProfileEntity";
import { ContractResponse } from "entities/products/ProductEntity";

import fileDownload from "js-file-download";

export async function uploadCustomerDocument(
  params: UploadCustomerDocumentParams
): Promise<{ document: CustomerDocument }> {
  try {
    const document: {
      document: CustomerDocument;
    } = await uploadCustomerDocumentById(
      params.id,
      params.body,
      params.type,
      params.subType
    );
    return document;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadCustomerDocument(
  params: DownloadCustomerDocumentParams
): Promise<FilePayload> {
  try {
    const res = await downloadCustomerDocumentById(
      params?.customerId,
      params.documentId
    );
    fileDownload(res.data.buffer, params.fileName, res.data.contentType);
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function downloadCustomersCsv(
  params: DownloadCustomersParams
): Promise<FilePayload> {
  try {
    const res = await downloadCustomersCSVDocument({
      keyword: params.keyword,
      from: params.from,
      to: params.to,
    });
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm:ss");
    fileDownload(
      res.data.buffer,
      `${params.fileName}-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}

export async function fetchCustomerDetails(
  params: CustomerDetailsParams
): Promise<CustomerDetails> {
  try {
    const result: [
      CustomerResponse,
      Profile[],
      EnhancedAddress[],
      CustomerDocument[],
      FinancialData,
      AccountInfoResponse,
      ContractResponse
    ] = await Promise.all([
      getCustomerByCustomerId(params.customerId),
      getCustomerProfilesByCustomerId(params.customerId),
      getCustomerAddresses(params.customerId),
      getCustomerDocumentsByCustomerId(params.customerId),
      getCustomerFinancialByCustomerId(params.customerId),
      getCustomerAccounts(params.customerId),
      getCustomerProductsByPartyId(params.customerId),
    ]);

    const [
      customerResponse,
      profiles,
      addresses,
      documents,
      financialData,
      accountsResponse,
      products,
    ]: [
      customerResponse: CustomerResponse,
      profiles: Profile[],
      addresses: EnhancedAddress[],
      documents: CustomerDocument[],
      financialData: FinancialData,
      accountsResponse: AccountInfoResponse,
      products: ContractResponse
    ] = result;

    const { customer }: CustomerResponse = customerResponse;

    const {
      total: totalAccounts,
      accountInfos: accounts,
    }: AccountInfoResponse = accountsResponse;

    return {
      customer,
      profiles,
      addresses,
      documents,
      financialData,
      accounts,
      totalAccounts,
      products,
    };
  } catch (e: unknown) {
    throw e;
  }
}
