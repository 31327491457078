import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { ColDef } from "@material-ui/data-grid";
import IdentityDocBlock from "components/molecules/verifications/IdentityDocBlock";
import { formatDate } from "utils";
import {
  acceptTask,
  rejectTask,
  updateDocument,
  updateDocumentDetails,
} from "api/tasks";
import { useSelector } from "react-redux";
import * as fromDataset from "store/reducers/dataset.reducer";
import VerificationTemplate from "templates/VerificationTemplate";
import { getPartiesCountry } from "store/reducers/app.reducer";
import jwt_decode from "jwt-decode";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import SelfieDocBlock from "components/molecules/verifications/SelfieDocBlock";
import { Grid, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import StatusBadge from "components/atoms/StatusBadge";
import { IndividualDetails } from "./IndividualIdentityVerification";
import { getPartiesCountries } from "store/reducers/dataset.reducer";
import { handleFailure } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";
import ApproveRejectBox from "components/atoms/ApproveRejectBox";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";

const useStyles = makeStyles((theme) => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperTwoThirds: {
    width: "66%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
  jsonWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyItems: "stretch",
    alignItems: "baseline",
    gap: "30px",
  },
  jsonItem: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "10px",
    paddingLeft: "20px",
    width: "100%",
  },
  extractGbg: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: "20px",
    gap: "20px",
  },
  detailsGBGCn: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: "20px",
    gap: "20px",
    marginLeft: "20px",
  },
  detailsBlocks: {
    display: "flex",
    marginBottom: "20px",
    gap: "20px",
    paddingLeft: "20px",
  },
  detailsTitle: {
    color: theme.palette.secondary.main,
    marginBottom: "5px",
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  detailsGBGTitle: {
    color: theme.palette.primary.main,
  },
  resultDetailsCn: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
  },
  listDetailsGBGCn: {
    display: "flex",
    gap: "6px",
    flexDirection: "column",
  },
  status: {
    width: "100px",
    display: "flex",
    justifyItems: "start",
  },
  gbgPersonEntryIdCn: {
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
}));

export interface IdentityDetails {
  [key: string]: string;
}

export interface extractedField {
  LocalValue: string;
  Name: string;
  OCRLocalValue: string;
  OCRValue: string;
  Value: string;
}

export interface gbgExtractedField {
  IssuingCountryCode: string;
  IssuingCountryName: string;
  IssuingStateName: string;
  DocumentCategory: string;
  DocumentType: string;
  DocumentName: string;
  DocumentSide: string;
  IsValidated: string | boolean;
  ScanDateTime: string | Date;
  HighLevelResult: string;
  ExtractedFields?: Array<extractedField>;
}

const PersonGBGEKYCVerification: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const { t } = useTranslation("validations");
  const classes = useStyles();
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();
  const countries = useSelector(getPartiesCountries);
  const taskVariables = task?.variables;
  const personDetail = taskVariables?.customer ?? taskVariables?.person;
  const customerAddressDetail = task?.variables?.address;
  const resultDetails = taskVariables?.gbgResultDetails;
  const extractedFields = taskVariables?.gbgExtractedFields;
  const gbgPersonEntryId = taskVariables?.gbgPersonEntryId;
  const idDocuments = task?.documents?.filter((d) => d.type === "ID_DOCUMENT");

  const [detailsOpen, setDetailsOpen] = useState(999);

  const selfieDocuments = task?.documents?.filter(
    (d) => d.type === "SELFIE"
  )[0];
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const birthCountry = useSelector(
    (state: { dataset: fromDataset.DatasetSliceState }) =>
      getPartiesCountry(state, personDetail?.countryOfBirth)
  );
  const nationality = useSelector(
    (state: { dataset: fromDataset.DatasetSliceState }) =>
      getPartiesCountry(state, personDetail?.nationality)
  );

  const getCountry = (code: string) => {
    if (countries) {
      const country = countries.find((country) => country.code === code);
      return country?.text || "n/a";
    }
    return "n/a";
  };

  if (!task) {
    return null;
  }

  const correctRequirementCheck = {
    ALL_IS_OK: false,
  };

  const toPrint: IdentityDetails = {
    personId: personDetail?.id,
    personName: personDetail?.name,
    personSurname: personDetail?.surname,
    personFiscalCode: personDetail?.fiscalCode || "n/a",
    personGender: personDetail?.gender || "n/a",
    personBirthDate: personDetail?.birthDate
      ? formatDate(personDetail.birthDate)
      : "n/a",
    personCountryOfBirth: birthCountry || "",
    personNationality: nationality || "",
    personType: taskVariables?.personType,
  };

  const customerAddressToPrint: IndividualDetails = {
    addressLine1: customerAddressDetail?.addressLine1,
    addressLine2: customerAddressDetail?.addressLine2 || "--",
    city: customerAddressDetail?.city,
    country: getCountry(customerAddressDetail.country),
    postalCode: customerAddressDetail?.postalCode,
    state: customerAddressDetail?.state,
  };

  const gbgPersonEntryIdToPrint = {
    gbgPersonEntryId,
  };

  const gbgFields = extractedFields?.map(
    (field: gbgExtractedField, index: number) => {
      const toPrintGbg: gbgExtractedField = {
        IssuingCountryCode: field.IssuingCountryCode,
        IssuingCountryName: field.IssuingCountryName,
        IssuingStateName: field.IssuingStateName,
        DocumentCategory: field.DocumentCategory,
        DocumentType: field.DocumentType,
        DocumentName: field.DocumentName,
        DocumentSide: field.DocumentSide,
        HighLevelResult: field.HighLevelResult,
        IsValidated: field.IsValidated,
        ScanDateTime: format(
          new Date(field.ScanDateTime),
          "dd/MM/yyyy HH:mm:ss"
        ),
      };

      return (
        <Grid item xs={12}>
          <div className={classes.extractGbg}>
            <DetailsBlock
              toPrint={toPrintGbg}
              label={toPrintGbg.DocumentType}
            />
            <div
              onClick={() =>
                detailsOpen === index
                  ? setDetailsOpen(999)
                  : setDetailsOpen(index)
              }
            >
              <Typography variant="h6" className={classes.detailsTitle}>
                Details +
              </Typography>
            </div>
            {detailsOpen === index && (
              <Grid spacing={4} container>
                {field.ExtractedFields?.map(
                  (extractedField: extractedField) => {
                    return (
                      <Grid item xs={6}>
                        <DetailsBlock
                          toPrint={extractedField}
                          label={extractedField.Name}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            )}
          </div>
        </Grid>
      );
    }
  );

  const handleApproveTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <VerificationTemplate
      refetch={fetch}
      task={task}
      columns={columns}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        <div className={classes.detailsWrapperTwoThirds}>
          <div className={classes.detailsBlocks}>
            {personDetail && (
              <DetailsBlock toPrint={toPrint} label="person_details__title" />
            )}
            {customerAddressDetail && (
              <div className={classes.detailsWrapperThird}>
                <DetailsBlock
                  toPrint={customerAddressToPrint}
                  label="individual__address__details__title"
                />
              </div>
            )}
          </div>
          {gbgPersonEntryId && (
            <div className={classes.gbgPersonEntryIdCn}>
              <DetailsBlock
                toPrint={gbgPersonEntryIdToPrint}
                label="individual__gbgPersonEntryId__details__title"
              />
            </div>
          )}

          <Grid container spacing={4} direction="column">
            {gbgFields}
          </Grid>
        </div>

        <div className={classes.detailsWrapperThird}>
          {idDocuments?.length && (
            <IdentityDocBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              updateDoc={(params) => updateDocument({ ...params, taskKey })}
              updateDocDetails={(params) =>
                updateDocumentDetails({ ...params, taskKey })
              }
              refetch={refetch}
              docs={idDocuments}
              taskId={taskId}
              isAssignedToUser={isAssignedToUser}
            />
          )}
          {selfieDocuments && (
            <SelfieDocBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              refetch={async () => {}}
              doc={selfieDocuments}
              taskId={taskId}
              isAssignedToUser={false}
              taskKey={"IdentityVerification"}
            />
          )}
        </div>
        {resultDetails?.length > 0 && (
          <div className={classes.detailsGBGCn}>
            <Typography variant="h6" className={classes.detailsGBGTitle}>
              {t("verification__gbg_result_details")}
            </Typography>
            <div className={classes.listDetailsGBGCn}>
              {resultDetails?.map((result: any) => (
                <div className={classes.resultDetailsCn}>
                  <div>{t(`approvalChecks.${result.split(":")?.[0]}`)}:</div>
                  <div className={classes.status}>
                    <StatusBadge status={result.split(":")?.[1]} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            task={task}
            checks={correctRequirementCheck}
            accept={(request) => handleApproveTask(request)}
            reject={(request) => handleRejectTask(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default PersonGBGEKYCVerification;
