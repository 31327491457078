import React, { useEffect, useState, ChangeEvent } from "react";
import {
  Button,
  Grid,
  makeStyles,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import { useHistory } from "react-router";
import {
  Detail,
  KeyFact,
  Opportunity,
} from "entities/opportunities/opportunityEntity";
import { updateImage, updateLogo, updateMediImage } from "api/opportunities";
import QuidUploadField from "components/atoms/QuidUploadField";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import OpportunityImage from "components/molecules/verifications/OpportunityImage";
import { getInvestementReferenceData } from "api/investments";
import QuidTitle from "components/atoms/QuidTitle";
import { format } from "date-fns";
import QuidTextareaField from "components/atoms/QuidTextareaField";
import { ToggleButton } from "@material-ui/lab";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ConfirmationModal from "components/atoms/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  gridContainerInfo: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,

    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  jsonFormWrapper: {
    width: "200px",
    marginTop: 24,
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
  alignImage: { display: "flex", margin: "auto" },
  gridContainerImages: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    justifyContent: "center",
    alignItems: "end",
    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  gridContainerDataFile: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    justifyContent: "center",
    alignItems: "end",
    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  containerJson: {
    marginBottom: "70px",
  },
  gridContainerJson: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
  },
  gridContainerJsonRow: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
  },
  sectionTitleBox: {
    cursor: "pointer",
    border: "1px solid #efefef",
    padding: "10px",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
  },

  tableRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonsCn: {
    display: "flex",
    gap: 16,
  },
  rowCn: {
    display: "flex",
    gap: 16,
  },
  arrowForward: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

type FormValues = {
  name?: string;
  currency?: string;
  assetClass?: string;
  step?: string;
  minRequested: string;
  maxRequested: string;
  network: string;
  horizon: string;
  stage: string;
  sector: string;
  tokenPrice: string;
  valuation: string;
  shortDescription: string;
  longDescription: string;
  startDate?: string | Date;
  endDate?: Date | string;
  noToken: number;
  bigImageId: number;
  logoImageId: number;
  details: Detail[];
  keyFacts: KeyFact[];
  beneficiaryWalletsWalletId: string;
  beneficiaryWalletsCurrency: string;
  smartContractName?: string;
  smartContractTemplate?: string;
  smartContractAmount?: string;
  smartContractDescription?: string;
  smartContractPrecision?: number;
  smartContractAddress?: string;
  smartContractNetwork?: string;
  streamableWalletAddress?: string;
  streamableCurrency?: string;
  streamableSetupType?: string;
  streamableSetupApy?: string;
  streamableSetupInvestment?: string;
  streamableSetupDuration?: string;
  referralPercentage?: number;
};

export interface OpportunitiesDetailsProps {
  opportunity?: Opportunity;
  opportunityId?: number;
  mode: string;
  onFormSubmit: any;
  isLoading: boolean;
}

const OpportunitiesCreateOrEdit: React.FC<OpportunitiesDetailsProps> = ({
  opportunity,
  opportunityId,
  mode,
  onFormSubmit,
  isLoading,
}: OpportunitiesDetailsProps) => {
  const { t } = useTranslation("opportunities");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [sectors, setSectors] = useState<string[]>([]);
  const [currency, setCurrency] = useState<string[]>([]);
  const [opportunities, setOpportunities] = useState<string[]>([]);
  const [stage, setStage] = useState<string[]>([]);
  const [steps, setSteps] = useState<string[]>([]);
  const [templates, setTemplates] = useState<string[]>([]);
  const [currentIssueDate, setCurrentIssueDate] = useState("");
  const [indexDetails, setIndexDetails] = useState<number>(-1);
  const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
  const [indexCms, setIndexCms] = useState<number | undefined>(undefined);
  const [smartContractToggle, setSmartContractToggle] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [isStreamable, setIsStreamable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    data: {} as FormValues,
    updateAPYValueModal: false,
  });

  const defaultValues: FormValues = {
    name: "",
    currency: "",
    assetClass: "",
    minRequested: "",
    maxRequested: "",
    network: "",
    horizon: "",
    stage: "",
    sector: "",
    tokenPrice: "",
    valuation: "",
    shortDescription: "",
    longDescription: "",
    startDate: new Date(),
    endDate: new Date(),
    noToken: 0,
    bigImageId: 0,
    logoImageId: 0,
    details: [],
    keyFacts: [],
    beneficiaryWalletsWalletId: "",
    beneficiaryWalletsCurrency: "",
    smartContractName: "",
    smartContractTemplate: "",
    smartContractAmount: "",
    smartContractDescription: "",
    smartContractPrecision: 0,
    smartContractAddress: "",
    smartContractNetwork: "",
    step: "",
    streamableWalletAddress: "",
    streamableCurrency: "",
    streamableSetupType: "",
    streamableSetupApy: "",
    streamableSetupInvestment: "",
    streamableSetupDuration: "",
    referralPercentage: 0,
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    let isMounted = true;
    if (isMounted && opportunity) {
      reset(({
        name: opportunity?.name || "",
        currency: opportunity?.currency || "",
        assetClass: opportunity.assetClass || "",
        minRequested: opportunity?.minRequested || "",
        maxRequested: opportunity?.maxRequested || "",
        network: opportunity?.smartContract?.network || "",
        referralPercentage: opportunity?.referralPercentage || "",
        horizon: opportunity?.horizon || "",
        stage: opportunity?.stage || "",
        sector: opportunity?.sector || "",
        tokenPrice: opportunity?.tokenPrice || "",
        valuation: opportunity?.valuation || "",
        shortDescription: opportunity?.shortDescription || "",
        longDescription: opportunity?.longDescription || "",
        startDate: opportunity?.startDate
          ? format(new Date(opportunity?.startDate), "yyyy-MM-dd")
          : "",
        endDate: opportunity?.endDate
          ? format(new Date(opportunity?.endDate), "yyyy-MM-dd")
          : "",
        noToken: opportunity?.noToken || 0,
        bigImageId: opportunity?.bigImageId || 0,
        logoImageId: opportunity?.logoImageId || 0,
        details: opportunity?.details || [],
        keyFacts: opportunity?.keyFacts || [],
        beneficiaryWalletsWalletId:
          opportunity?.beneficiaryWallets?.wallets[0]?.walletId || "",
        beneficiaryWalletsCurrency:
          opportunity?.beneficiaryWallets?.wallets?.[0].currency || "",
        smartContractName: opportunity?.smartContract?.name || "",
        smartContractTemplate: opportunity?.smartContract?.template || "",
        smartContractAmount: opportunity?.smartContract?.amount || "",
        smartContractDescription: opportunity?.smartContract?.description || "",
        smartContractPrecision: opportunity?.smartContract?.precision || 0,
        smartContractAddress: opportunity?.smartContract?.address || "",
        smartContractNetwork: opportunity?.smartContract?.network || "",
        step: opportunity?.step || "",
        streamableWalletAddress: opportunity?.streamableWalletAddress || "",
        streamableCurrency: opportunity?.streamableCurrency || "",
        streamableSetupType: opportunity?.opportunitySetup?.type,
        streamableSetupApy: opportunity?.opportunitySetup?.apy,
        streamableSetupInvestment: opportunity?.opportunitySetup?.investment,
        streamableSetupDuration: opportunity?.opportunitySetup?.duration,
      } as unknown) as FormValues);
      setIsStreamable(opportunity?.opportunitySetup ? true : false);
    }

    return (): void => {
      isMounted = false;
    };
  }, [opportunity]);

  const onHandleSubmit = (values: FormValues) => {
    if (
      mode === "update" &&
      values.streamableSetupApy?.toString() !==
        opportunity?.opportunitySetup?.apy?.toString()
    ) {
      if (
        values.streamableSetupApy?.toString() !==
        opportunity?.opportunitySetup?.apy?.toString()
      ) {
        setIsModalOpen({ updateAPYValueModal: true, data: values });
      }
    } else {
      onSubmit(values);
    }
  };

  const onSubmit = (values: FormValues) => {
    if (
      values.startDate === null ||
      values.endDate === null ||
      values.startDate === "" ||
      values.endDate === ""
    ) {
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("opportunity__dates_not_defined"),
          type: "warning",
        })
      );
    } else {
      if (mode === "create") {
        return onFormSubmit({
          ...values,
        });
      }

      if (mode === "update") {
        setIsModalOpen({ updateAPYValueModal: false, data: {} as FormValues });
        return onFormSubmit({
          ...values,
          noToken: opportunity?.noToken || null,
          bigImageId: opportunity?.bigImageId || null,
          logoImageId: opportunity?.logoImageId || null,
          details: opportunity?.details || null,
          keyFacts: opportunity?.keyFacts || null,
          network: opportunity?.smartContract?.network,
        });
      }
    }
  };

  const onSubmitJson = () => {
    return onFormSubmit({
      ...opportunity,
      beneficiaryWalletsWalletId:
        opportunity?.beneficiaryWallets?.wallets[0]?.walletId || "",
      beneficiaryWalletsCurrency:
        opportunity?.beneficiaryWallets?.wallets?.[0].currency || "",
      streamableSetupType: opportunity?.opportunitySetup?.type,
      streamableSetupApy: opportunity?.opportunitySetup?.apy,
      streamableSetupInvestment: opportunity?.opportunitySetup?.investment,
      streamableSetupDuration:
        opportunity?.opportunitySetup?.type === "INSTANT_STREAMABLE_TYPE"
          ? "0"
          : opportunity?.opportunitySetup?.duration,
      startDate: opportunity?.startDate
        ? format(new Date(opportunity?.startDate), "yyyy-MM-dd")
        : "",
      endDate: opportunity?.endDate
        ? format(new Date(opportunity?.endDate), "yyyy-MM-dd")
        : "",
      network: opportunity?.smartContract?.network,
    });
  };

  const fetchReferenceDataValues = async () => {
    try {
      const res = await getInvestementReferenceData();
      setSteps(res?.steps);
      setSectors(res?.sectors);
      setStage(res?.equityStages);
      setOpportunities(res?.opportunities);
      setCurrency(res?.currencies);
      setTemplates(res?.templates);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    fetchReferenceDataValues();
    setIsStreamable(process.env.REACT_APP_PROJECT === "strfiinvest");
  }, [dispatch, opportunity]);

  const onImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (opportunityId && e.target?.files?.length === 1) {
      try {
        await updateImage({
          opportunityId,
          request: { image: e.target.files[0] },
        });
        handleSuccessfulMessage(t("details__label__image_uploaded"));
        history.push(`/opportunities/${opportunityId}`);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  const onLogoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (opportunityId && e.target?.files?.length === 1) {
      try {
        await updateLogo({
          opportunityId,
          request: { image: e.target.files[0] },
        });
        handleSuccessfulMessage(t("details__label__logo_uploaded"));
        history.push(`/opportunities/${opportunityId}`);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  const onMediumImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (opportunityId && e.target?.files?.length === 1) {
      try {
        await updateMediImage({
          opportunityId,
          request: { image: e.target.files[0] },
        });
        handleSuccessfulMessage(t("details__label__logo_uploaded"));
        history.push(`/opportunities/${opportunityId}`);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  // function that moves the selected object up in the list by 1 postion
  // pre is a support costant to save the details of the object to be replaced
  // @params
  // index: index of the object to move
  const moveUp = (index: number) => {
    if (opportunity && opportunityId && opportunity.details) {
      const pre = opportunity.details[index - 1];
      opportunity.details = opportunity.details ?? ([] as Detail[]);
      opportunity.details[index - 1] = (opportunity.details[
        index
      ] as unknown) as Detail;
      opportunity.details[index] = pre;

      onFormSubmit({
        ...opportunity,
        beneficiaryWalletsWalletId:
          opportunity?.beneficiaryWallets?.wallets[0]?.walletId || "",
        beneficiaryWalletsCurrency:
          opportunity?.beneficiaryWallets?.wallets?.[0].currency || "",
        streamableSetupType: opportunity?.opportunitySetup?.type,
        streamableSetupApy: opportunity?.opportunitySetup?.apy,
        streamableSetupInvestment: opportunity?.opportunitySetup?.investment,
        streamableSetupDuration:
          opportunity?.opportunitySetup?.type === "INSTANT_STREAMABLE_TYPE"
            ? "0"
            : opportunity?.opportunitySetup?.duration,
        startDate: opportunity?.startDate
          ? format(new Date(opportunity?.startDate), "yyyy-MM-dd")
          : "",
        endDate: opportunity?.endDate
          ? format(new Date(opportunity?.endDate), "yyyy-MM-dd")
          : "",
        network: opportunity?.smartContract?.network,
      });
    }
  };

  // function that moves the selected object down in the list by 1 postion
  // post is a support costant to save the details of the object to be replaced
  // @params
  // index: index of the object to move
  const moveDown = (index: number) => {
    if (opportunity && opportunityId && opportunity.details) {
      const post = opportunity.details[index + 1];
      opportunity.details = opportunity.details ?? ([] as Detail[]);
      opportunity.details[index + 1] = (opportunity.details[
        index
      ] as unknown) as Detail;
      opportunity.details[index] = post;

      onFormSubmit({
        ...opportunity,
        beneficiaryWalletsWalletId:
          opportunity?.beneficiaryWallets?.wallets[0]?.walletId || "",
        beneficiaryWalletsCurrency:
          opportunity?.beneficiaryWallets?.wallets?.[0].currency || "",
        streamableSetupType: opportunity?.opportunitySetup?.type,
        streamableSetupApy: opportunity?.opportunitySetup?.apy,
        streamableSetupInvestment: opportunity?.opportunitySetup?.investment,
        streamableSetupDuration:
          opportunity?.opportunitySetup?.type === "INSTANT_STREAMABLE_TYPE"
            ? "0"
            : opportunity?.opportunitySetup?.duration,
        startDate: opportunity?.startDate
          ? format(new Date(opportunity?.startDate), "yyyy-MM-dd")
          : "",
        endDate: opportunity?.endDate
          ? format(new Date(opportunity?.endDate), "yyyy-MM-dd")
          : "",
        network: opportunity?.smartContract?.network,
      });
    }
  };

  const confirmationModalProps = {
    onSubmit: () => onSubmit(isModalOpen.data),
    isOpen: isModalOpen.updateAPYValueModal,
    labelButtonClose: t("opportunity__label_close__modal"),
    title: t("opportunity__title_modal__update_apy_value"),
    labelButtonSubmit: t("opportunity__label_submit__modal"),
    description: t("opportunity__description_modal__update_apy_value"),
    onCloseModal: () =>
      setIsModalOpen({ updateAPYValueModal: false, data: {} as FormValues }),
  };

  useEffect(() => {
    if (indexDetails === -1) {
      setIsDetailsVisible(false);
    } else {
      setIsDetailsVisible(true);
    }
  }, [indexDetails]);

  return (
    <>
      <ConfirmationModal {...confirmationModalProps} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onHandleSubmit)}>
          <Grid
            container
            spacing={1}
            direction="row"
            className={classes.gridContainerInfo}
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t("opportunity__section_title__genaral_info")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="name"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__opportunity_name__required"
                  ) as string,
                }}
                label={t("details__label__opportunityName")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="currency"
                label={t("details__label__currency")}
                rules={{
                  required: t("details__label__currency__required") as string,
                }}
                defaultValues={defaultValues}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allCurrency")}</em>
                </MenuItem>
                {currency?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="assetClass"
                label={t("details__label__assetClass")}
                rules={{
                  required: t(
                    "details__label__asset_class__required"
                  ) as string,
                }}
                defaultValues={defaultValues}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allAssetClasses")}</em>
                </MenuItem>
                {opportunities?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="step"
                label={t("details__label__step")}
                rules={{
                  required: t("details__label__step__required") as string,
                }}
                defaultValues={defaultValues}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                {!isStreamable && (
                  <MenuItem value="">
                    <em>{t("values__allSteps")}</em>
                  </MenuItem>
                )}
                {steps?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="minRequested"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__min_requested__required"
                  ) as string,
                }}
                label={t("details__label__minRequested")}
                defaultValues={defaultValues}
              />
            </Grid>
            {!isStreamable && (
              <Grid item xs={4}>
                <QuidTextField
                  name="maxRequested"
                  textFieldProps={{
                    type: "number",
                    fullWidth: true,
                  }}
                  rules={{
                    required: t(
                      "details__label__max_requested__required"
                    ) as string,
                  }}
                  label={t("details__label__maxRequested")}
                  defaultValues={defaultValues}
                />
              </Grid>
            )}
            {!isStreamable && (
              <Grid item xs={4}>
                <QuidTextField
                  name="horizon"
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  rules={{
                    required: t("details__label__horizon__required") as string,
                  }}
                  label={t("details__label__horizon")}
                  defaultValues={defaultValues}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <QuidTextField
                name="stage"
                label={t("details__label__stage")}
                rules={undefined}
                defaultValues={defaultValues}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allStages")}</em>
                </MenuItem>
                {stage?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {t(`values__stage__${value}`)}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="sector"
                label={t("details__label__sector")}
                rules={{
                  required: t("details__label__sector__required") as string,
                }}
                defaultValues={defaultValues}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allAssetClasses")}</em>
                </MenuItem>
                {sectors?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            {!isStreamable && (
              <Grid item xs={4}>
                <QuidTextField
                  name="tokenPrice"
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  rules={{
                    required: t(
                      "details__label__token_price__required"
                    ) as string,
                  }}
                  label={t("details__label__tokenPrice")}
                  defaultValues={defaultValues}
                />
              </Grid>
            )}
            {!isStreamable && (
              <Grid item xs={4}>
                <QuidTextField
                  name="noToken"
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  rules={{
                    required: t("details__label__no_token__required") as string,
                  }}
                  label={t("details__label__noToken")}
                  defaultValues={defaultValues}
                />
              </Grid>
            )}
            {!isStreamable && (
              <Grid item xs={4}>
                <QuidTextField
                  name="valuation"
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  rules={{
                    required: t(
                      "details__label__valuation__required"
                    ) as string,
                  }}
                  label={t("details__label__valuation")}
                  defaultValues={defaultValues}
                />
              </Grid>
            )}
            {isStreamable && mode === "create" && (
              <Grid item xs={4}>
                <QuidTextField
                  name="network"
                  label={t("details__label__network")}
                  rules={{
                    required: t("details__label__network__required") as string,
                  }}
                  defaultValues={defaultValues}
                  textFieldProps={{
                    select: true,
                    fullWidth: true,
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--</em>
                  </MenuItem>
                  <MenuItem value={"BINANCE_MAIN_NET"}>
                    {"BINANCE_MAIN_NET"}
                  </MenuItem>
                  <MenuItem value={"BINANCE_TEST_NET"}>
                    {"BINANCE_TEST_NET"}
                  </MenuItem>
                </QuidTextField>
              </Grid>
            )}
            <Grid item xs={12}></Grid>
            <Grid item xs={4}>
              <QuidTextareaField
                name="shortDescription"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__short_description__required"
                  ) as string,
                }}
                label={t("details__label__shortDescription")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={8}>
              <QuidTextareaField
                name="longDescription"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__long_description__required"
                  ) as string,
                }}
                label={t("details__label__longDescription")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="startDate"
                textFieldProps={{
                  type: "date",
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                }}
                rules={{}}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCurrentIssueDate(e?.target?.value);
                }}
                label={t("details__label__start_date")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="endDate"
                inputProps={{
                  min: currentIssueDate,
                }}
                textFieldProps={{
                  type: "date",
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                }}
                rules={{}}
                label={t("details__label__end_date")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t("opportunity__section_title__beneficiary_wallets")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="beneficiaryWalletsWalletId"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{}}
                label={t("details__label__wallet_id")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="beneficiaryWalletsCurrency"
                label={t("details__label__currency")}
                rules={{}}
                defaultValues={defaultValues}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allCurrency")}</em>
                </MenuItem>
                {currency?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            {!isStreamable && (
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  {t("opportunity__section_title__smart_contract")}
                </Typography>
                {opportunity?.smartContract?.address === undefined ? (
                  <>
                    <ToggleButton
                      onClick={() =>
                        setSmartContractToggle(!smartContractToggle)
                      }
                      selected={smartContractToggle}
                    />
                    {smartContractToggle && (
                      <Grid container spacing={1} direction="row">
                        <Grid item xs={4}>
                          <QuidTextField
                            name="smartContractName"
                            textFieldProps={{
                              fullWidth: true,
                            }}
                            rules={
                              smartContractToggle && {
                                required: t(
                                  "details__label__opportunity_smart_contract_name__required"
                                ) as string,
                              }
                            }
                            label={t(
                              "details__label__opportunity_smart_contract_name"
                            )}
                            defaultValues={defaultValues}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <QuidTextField
                            name="smartContractTemplate"
                            rules={
                              smartContractToggle && {
                                required: t(
                                  "details__label__opportunity_smart_contract_template__required"
                                ) as string,
                              }
                            }
                            label={t(
                              "details__label__opportunity_smart_contract_template"
                            )}
                            defaultValues={defaultValues}
                            textFieldProps={{
                              select: true,
                              fullWidth: true,
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                          >
                            <MenuItem value="">
                              <em>{t("values__allStages")}</em>
                            </MenuItem>
                            {templates?.map((value, index) => (
                              <MenuItem key={`${value}-${index}`} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </QuidTextField>
                        </Grid>
                        <Grid item xs={4}>
                          <QuidTextField
                            name="smartContractAmount"
                            textFieldProps={{
                              fullWidth: true,
                            }}
                            rules={
                              smartContractToggle && {
                                required: t(
                                  "details__label__opportunity_smart_contract_amount__required"
                                ) as string,
                              }
                            }
                            label={t(
                              "details__label__opportunity_smart_contract_amount"
                            )}
                            defaultValues={defaultValues}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <QuidTextField
                            name="smartContractPrecision"
                            textFieldProps={{
                              fullWidth: true,
                            }}
                            rules={
                              smartContractToggle && {
                                required: t(
                                  "details__label__opportunity_smart_contract_precision__required"
                                ) as string,
                              }
                            }
                            label={t(
                              "details__label__opportunity_smart_contract_precision"
                            )}
                            defaultValues={defaultValues}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <QuidTextField
                            name="smartContractDescription"
                            textFieldProps={{
                              fullWidth: true,
                            }}
                            rules={
                              smartContractToggle && {
                                required: t(
                                  "details__label__opportunity_smart_contract_description__required"
                                ) as string,
                              }
                            }
                            label={t(
                              "details__label__opportunity_smart_contract_description"
                            )}
                            defaultValues={defaultValues}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item xs={4}>
                      <QuidTextField
                        name="smartContractAddress"
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={undefined}
                        label={t(
                          "details__label__opportunity_smart_contract_address"
                        )}
                        disabled
                        defaultValues={defaultValues}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <QuidTextField
                        name="smartContractNetwork"
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={undefined}
                        label={t(
                          "details__label__opportunity_smart_contract_network"
                        )}
                        disabled
                        defaultValues={defaultValues}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            )}
            {isStreamable && (
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  {t("opportunity__section_title__streamable_smart_contract")}
                </Typography>
                {opportunity?.smartContract?.address === undefined ? (
                  <>
                    <Grid container spacing={1} direction="row">
                      <Grid item xs={4}>
                        <QuidTextField
                          name="streamableWalletAddress"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={undefined}
                          label={t(
                            "details__label__opportunity_streamable_wallet_address"
                          )}
                          defaultValues={defaultValues}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <QuidTextField
                          name="streamableCurrency"
                          label={t(
                            "details__label__opportunity_streamable_currency"
                          )}
                          rules={{}}
                          defaultValues={defaultValues}
                          textFieldProps={{
                            select: true,
                            fullWidth: true,
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                        >
                          <MenuItem value="">
                            <em>{t("values__allCurrency")}</em>
                          </MenuItem>
                          {currency?.map((value, index) => (
                            <MenuItem key={`${value}-${index}`} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </QuidTextField>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={4}>
                      <QuidTextField
                        name="streamableWalletAddress"
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={undefined}
                        label={t(
                          "details__label__opportunity_streamable_wallet_address"
                        )}
                        disabled
                        defaultValues={defaultValues}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <QuidTextField
                        name="streamableCurrency"
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={undefined}
                        label={t(
                          "details__label__opportunity_streamable_currency"
                        )}
                        disabled
                        defaultValues={defaultValues}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            )}
            {isStreamable && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    {t("opportunity__section_title__streamable_setup")}
                  </Typography>
                  <Grid container spacing={1} direction="row">
                    <Grid item xs={4}>
                      <QuidTextField
                        disabled={mode === "update"}
                        name="streamableSetupType"
                        label={t("details__label__type")}
                        rules={{
                          required: t(
                            "details__label__type__required"
                          ) as string,
                        }}
                        defaultValues={defaultValues}
                        textFieldProps={{
                          select: true,
                          fullWidth: true,
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                      >
                        <MenuItem value="LOCKED_STREAMABLE_TYPE">
                          <em>{t("locked_streamable_type")}</em>
                        </MenuItem>
                        <MenuItem value="INSTANT_STREAMABLE_TYPE">
                          <em>{t("instant_streamable_type")}</em>
                        </MenuItem>
                      </QuidTextField>
                    </Grid>
                    <Grid item xs={4}>
                      <QuidTextField
                        disabled={
                          mode === "update" &&
                          methods
                            .watch("streamableSetupType")
                            ?.toUpperCase() === "LOCKED_STREAMABLE_TYPE"
                            ? true
                            : false
                        }
                        name="streamableSetupApy"
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={{
                          required: t(
                            "details__label__apy__required"
                          ) as string,
                        }}
                        label={t("details__label__apy")}
                        defaultValues={defaultValues}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <QuidTextField
                        name="streamableSetupInvestment"
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={{
                          required: t(
                            "details__label__investment__required"
                          ) as string,
                        }}
                        label={t("details__label__investment")}
                        defaultValues={defaultValues}
                      />
                    </Grid>
                    {methods.watch("streamableSetupType")?.toUpperCase() !==
                      "INSTANT_STREAMABLE_TYPE" && (
                      <Grid item xs={4}>
                        <QuidTextField
                          name="streamableSetupDuration"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={{
                            required: t(
                              "details__label__dutation__required"
                            ) as string,
                          }}
                          label={t("details__label__duration")}
                          defaultValues={defaultValues}
                          disabled={
                            methods
                              .watch("streamableSetupType")
                              ?.toUpperCase() === "INSTANT_STREAMABLE_TYPE"
                              ? true
                              : false
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <QuidTextField
                        name="referralPercentage"
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={{
                          pattern: {
                            value: new RegExp("^(100|[1-9]?[0-9])$"),
                            message: t(
                              "details__label__referral_percentage__required_number_between_0_100"
                            ),
                          },
                        }}
                        label={t("details__label__referral_percentage")}
                        defaultValues={defaultValues}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {mode === "create" && (
              <Grid item xs={12}>
                <Button
                  className={classes.updateBtn}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  type="submit"
                >
                  {t("opportunity__create_page__create_button")}
                </Button>
              </Grid>
            )}
            {mode === "update" && (
              <Grid item xs={12}>
                <Button
                  className={classes.updateBtn}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  type="submit"
                >
                  {t("opportunity__update_button")}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
      {mode === "update" && (
        <>
          <div className={classes.containerJson}>
            <QuidTitle>{t("details__label__title__opportinity_cms")}</QuidTitle>
            <div className={classes.gridContainerJsonRow}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("details__label__json_details")}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {opportunity?.details?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setIsDetailsVisible(false);
                          setIndexDetails(index);

                          if (indexDetails === index) {
                            setIndexDetails(-1);
                          }

                          setIndexCms(undefined);
                        }}
                        className={classes.sectionTitleBox}
                      >
                        <div>
                          <Typography
                            variant="h6"
                            className={classes.sectionTitle}
                          >
                            {item.title}
                          </Typography>
                          <Typography variant="body1">
                            {item.description}
                          </Typography>
                        </div>
                        <div>
                          {index !== 0 && (
                            <IconButton onClick={() => moveUp(index)}>
                              <ArrowUpwardIcon
                                className={classes.arrowForward}
                              />
                            </IconButton>
                          )}
                          {opportunity?.details &&
                            index !== opportunity?.details?.length - 1 && (
                              <IconButton onClick={() => moveDown(index)}>
                                <ArrowDownwardIcon
                                  className={classes.arrowForward}
                                />
                              </IconButton>
                            )}
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    variant="contained"
                    color="primary"
                    className="actions actions-reject"
                    onClick={() => {
                      if (opportunity) {
                        const newDetails = opportunity?.details?.length
                          ? [
                              ...opportunity?.details,
                              { title: "todo", description: "todo" },
                            ]
                          : [{ title: "todo", description: "todo" }];

                        opportunity.details = newDetails;
                        onSubmitJson();
                      }
                    }}
                  >
                    {t("add")}
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  {isDetailsVisible && (
                    <FormProvider {...methods}>
                      <form onSubmit={handleSubmit(onSubmitJson)}>
                        <QuidTextField
                          name="title"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={undefined}
                          label={t("title")}
                          defaultValues={{
                            title: opportunity?.details?.[indexDetails]?.title,
                          }}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <QuidTextareaField
                          name="description"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={{
                            required: t(
                              "details__label__short_description__required"
                            ) as string,
                          }}
                          label={t("description")}
                          defaultValues={{
                            description:
                              opportunity?.details?.[indexDetails]?.description,
                          }}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <div className={classes.buttonsCn}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="actions actions-reject"
                            onClick={() => {
                              if (opportunity) {
                                const newdetails = opportunity?.details || [
                                  { title: "", description: "" },
                                ];
                                newdetails[indexDetails] = {
                                  title,
                                  description,
                                };
                                opportunity.details = newdetails || [
                                  { title: "", description: "" },
                                ];
                                onSubmitJson();
                              }
                            }}
                          >
                            {t("modify")}
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="actions actions-reject"
                            onClick={() => {
                              if (opportunity) {
                                const newDetails = opportunity?.details;
                                newDetails?.splice(indexDetails, 1);
                                opportunity.details = newDetails;
                                setIndexDetails(-1);
                                onSubmitJson();
                              }
                            }}
                          >
                            {t("delete")}
                          </Button>
                        </div>
                      </form>
                    </FormProvider>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className={classes.gridContainerJsonRow}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("details__label__json_key_facts")}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Grid container direction="row" spacing={1}>
                    {opportunity?.keyFacts?.map((item, index) => {
                      return (
                        <Grid item xs={4}>
                          <div
                            key={index}
                            onClick={() => {
                              if (indexCms) {
                                setIndexCms(undefined);
                              } else {
                                setIndexCms(index);
                              }
                              setIndexDetails(-1);
                            }}
                            className={classes.sectionTitleBox}
                          >
                            <Typography
                              variant="h6"
                              className={classes.sectionTitle}
                            >
                              {item.title}
                            </Typography>
                            <Typography variant="body1">
                              {item.description}
                            </Typography>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    className="actions actions-reject"
                    onClick={() => {
                      if (opportunity) {
                        const newkeyFacts = opportunity?.keyFacts?.length
                          ? [
                              { title: "todo", description: "todo" },
                              ...opportunity?.keyFacts,
                            ]
                          : [{ title: "todo", description: "todo" }];

                        opportunity.keyFacts = newkeyFacts;
                        onSubmitJson();
                      }
                    }}
                  >
                    {t("add")}
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  {indexCms !== undefined && (
                    <FormProvider {...methods}>
                      <form onSubmit={handleSubmit(onSubmitJson)}>
                        <QuidTextField
                          name="title"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={undefined}
                          label={t("title")}
                          defaultValues={{
                            title: opportunity?.keyFacts?.[indexCms]?.title,
                          }}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <QuidTextareaField
                          name="description"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={{
                            required: t(
                              "details__label__short_description__required"
                            ) as string,
                          }}
                          label={t("description")}
                          defaultValues={{
                            description:
                              opportunity?.keyFacts?.[indexCms]?.description,
                          }}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <div className={classes.buttonsCn}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="actions actions-reject"
                            onClick={() => {
                              if (opportunity) {
                                const nexKeyfact = opportunity?.keyFacts || [
                                  { title: "", description: "" },
                                ];
                                nexKeyfact[indexCms] = {
                                  title,
                                  description,
                                };
                                opportunity.keyFacts = nexKeyfact || [
                                  { title: "", description: "" },
                                ];
                                onSubmitJson();
                              }
                            }}
                          >
                            {t("modify")}
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="actions actions-reject"
                            onClick={() => {
                              if (opportunity) {
                                const nexKeyfact = opportunity?.keyFacts;
                                nexKeyfact?.splice(indexCms, 1);
                                opportunity.keyFacts = nexKeyfact;
                                setIndexCms(undefined);
                                onSubmitJson();
                              }
                            }}
                          >
                            {t("delete")}
                          </Button>
                        </div>
                      </form>
                    </FormProvider>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.containerJson}>
            <QuidTitle>
              {t("details__label__title__opportinity_images")}
            </QuidTitle>
            <Grid
              container
              spacing={2}
              direction="row"
              className={classes.gridContainerImages}
            >
              <Grid item xs={4}>
                <OpportunityImage
                  imageId={opportunity?.mediumImageId as number}
                  type={"image"}
                  className={classes.alignImage}
                />
                <QuidUploadField
                  inputId="uploadMediumImage"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onMediumImageChange(e)
                  }
                  label={t("details__label__upload_medium_image")}
                />
              </Grid>
              <Grid item xs={4}>
                <OpportunityImage
                  imageId={opportunity?.bigImageId as number}
                  type={"image"}
                  className={classes.alignImage}
                />
                <QuidUploadField
                  inputId="uploadImage"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onImageChange(e)
                  }
                  label={t("details__label__upload_image")}
                />
              </Grid>
              <Grid item xs={4}>
                <OpportunityImage
                  imageId={opportunity?.logoImageId as number}
                  type={"image"}
                  className={classes.alignImage}
                />
                <QuidUploadField
                  inputId="uploadLogo"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onLogoChange(e)
                  }
                  label={t("details__label__upload_logo")}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default OpportunitiesCreateOrEdit;
