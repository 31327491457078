import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import { ServerFailure } from "features/core/Failure";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import {
  fetchCryptoBeneficiariesManagementByPartyId,
  fetchCryptoWalletBalancesByWalletIdId,
  fetchCryptoWalletsManagement,
  postCryptoPaymentsManagement,
} from "@portit/core/api/Crypto";
import {
  BalanceDetailResponse,
  BeneficiaryDetailResponse,
  WalletDetailResponse,
} from "@portit/core/entities/Crypto";
import Loader from "components/atoms/icons/Loader";
import { SearchIcon } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: "#FAFAFA" },
  containerInner: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  column4: { width: "calc(100% / 3 - 30px)", padding: "0px 15px" },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  containerInnerButtons: {
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 60,
  },
  checkText: {
    textAlign: "center",
    paddingTop: 13,
    fontFamily: "Nunito",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 18,
  },
  accountDetailsWrapper: {
    background: "#fff",
    padding: 15,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
  amountReasonWrapper: {
    marginTop: 16,
  },
}));

interface FormValues {
  walletIdFrom: number;
  beneficiaryWalletIdTo: number;
  amount: string;
  network: string;
  currency: string;
  smartContractId: number;
  reason: string;
}

const CryptoPaymentCreate: React.FC = () => {
  const { t } = useTranslation("payments");
  const [currencies, setCurrencies] = useState<BalanceDetailResponse[]>([]);
  const [keyword, setKeyword] = useState({
    value: "",
    filter: "",
  });
  const [loading, setLoading] = useState({
    currencies: false,
    fromWallet: false,
    toBeneficiary: false,
  });

  const [fromWallet, setFromWallet] = useState<WalletDetailResponse>();
  const [fromWallets, setFromWallets] = useState<WalletDetailResponse[]>([]);
  const [
    selectedCurrency,
    setSelectedCurrency,
  ] = useState<BalanceDetailResponse>();

  const [toBeneficiaries, setToBeneficiaries] = useState<
    BeneficiaryDetailResponse[]
  >([]);

  const [
    toBeneficiary,
    setToBeneficiary,
  ] = useState<BeneficiaryDetailResponse>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultValues = {
    currency: "",
    amount: 0,
    reason: "",
    walletIdFrom: 0,
    beneficiaryWalletIdTo: 0,
    network: "",
    smartContractId: 0,
    decimalPlaces: 0,
    tokenId: "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onResetForm = () => {
    setCurrencies([]);
    reset(defaultValues);
    setFromWallet(undefined);
    setToBeneficiary(undefined);
    setSelectedCurrency(undefined);
    setKeyword({ value: "", filter: "" });
    setLoading({
      fromWallet: false,
      currencies: false,
      toBeneficiary: false,
    });
  };

  const setBeneficiary = (beneficiary: BeneficiaryDetailResponse) => {
    if (beneficiary) {
      setToBeneficiary(beneficiary);
      getCurrencies(beneficiary);
    }
  };

  useEffect(() => {
    const getCryptoWalletsManagement = async (): Promise<void> => {
      setLoading({
        currencies: false,
        fromWallet: true,
        toBeneficiary: false,
      });
      try {
        const res = await fetchCryptoWalletsManagement({
          size: 99999,
          page: 0,
          ...(keyword?.filter && { keyword: keyword?.filter }),
        });
        setFromWallets(res?.wallets);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading({
          currencies: false,
          fromWallet: false,
          toBeneficiary: false,
        });
      }
    };

    void getCryptoWalletsManagement();
  }, [keyword?.filter]);

  const getCurrencies = async (
    beneficiary: BeneficiaryDetailResponse
  ): Promise<void> => {
    try {
      setLoading({ fromWallet: false, toBeneficiary: false, currencies: true });
      const beneficiaryCurrency = beneficiary?.wallets?.[0]?.currency;
      const beneficiaryWalletId = beneficiary?.wallets?.[0]?.beneficiaryWalletId?.toString();

      if (beneficiaryWalletId) {
        const { balances } = await fetchCryptoWalletBalancesByWalletIdId(
          beneficiaryWalletId
        );

        if (balances) {
          const currentBalances = balances?.filter(
            ({ currency }) =>
              currency?.toLowerCase() === beneficiaryCurrency?.toLowerCase()
          );

          if (currentBalances) {
            setCurrencies(currentBalances);
          }
        }
      }
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading({
        fromWallet: false,
        toBeneficiary: false,
        currencies: false,
      });
    }
  };

  useEffect(() => {
    const getCryptoWalletBeneficiaries = async (): Promise<void> => {
      if (fromWallet?.id !== undefined) {
        setLoading({
          fromWallet: false,
          toBeneficiary: true,
          currencies: false,
        });
        try {
          const res = await fetchCryptoBeneficiariesManagementByPartyId(
            fromWallet?.partyId
          );
          setToBeneficiaries(res?.beneficiaries);
        } catch (err: any) {
          const message =
            err instanceof ServerFailure
              ? (err as ServerFailure)?.error?.message
              : (err as NetworkFailure)?.message;
          dispatch(
            promptsSlice.actions.openSnackbar({
              message,
              type: "error",
            })
          );
        } finally {
          setLoading({
            fromWallet: false,
            toBeneficiary: false,
            currencies: false,
          });
        }
      }
    };

    void getCryptoWalletBeneficiaries();
  }, [fromWallet]);

  const onFormSubmit = async (values: FormValues) => {
    if (fromWallet?.id !== undefined && toBeneficiary?.name !== undefined) {
      try {
        if (selectedCurrency?.smartContractId === null) {
          dispatch(
            promptsSlice.actions.openSnackbar({
              message:
                "smartContractId is undefined. Ask to the team for verify the debit wallet.",
              type: "warning",
            })
          );
          return;
        }

        await postCryptoPaymentsManagement({
          walletIdFrom: fromWallet?.id,
          beneficiaryWalletIdTo:
            toBeneficiary?.wallets?.[0]?.beneficiaryWalletId ?? 0,
          amount: values?.amount,
          smartContractId: selectedCurrency?.smartContractId
            ? selectedCurrency.smartContractId
            : 0,
          reason: values?.reason,
        });
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: t("payment__success__message"),
            type: "success",
          })
        );
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    }
  };

  const fromWalletDetails = {
    "account holder":
      fromWallet?.customer?.type === "INDIVIDUAL"
        ? `${fromWallet?.customer?.name} ${fromWallet?.customer?.surname}`
        : "n/a",
    "account name": fromWallet?.customer?.user?.username
      ? fromWallet?.customer?.user?.username
      : "n/a",
    "user id": fromWallet?.customer?.user?.userId
      ? fromWallet?.customer?.user?.userId
      : "n/a",
    "account type": fromWallet?.customer?.type
      ? fromWallet?.customer?.type
      : "n/a",
    email: fromWallet?.customer?.email ? fromWallet?.customer?.email : "n/a",
    address: fromWallet?.walletAddress || "n/a",
  };

  const toBeneficiaryDetails = {
    name: toBeneficiary?.name ? toBeneficiary?.name : "n/a",
  };

  const currencyDetails = {
    currency: selectedCurrency?.currency ? selectedCurrency?.currency : "n/a",
    "crypto balance id": selectedCurrency?.cryptoBalanceId
      ? selectedCurrency?.cryptoBalanceId
      : "n/a",
    amount: selectedCurrency?.amount ? selectedCurrency?.amount : "n/a",
    "uint amount": selectedCurrency?.uintAmount
      ? selectedCurrency?.uintAmount
      : "n/a",
    network: selectedCurrency?.network ? selectedCurrency?.network : "n/a",
    "smart contract id": selectedCurrency?.smartContractId
      ? selectedCurrency?.smartContractId
      : "n/a",
  };

  return (
    <MainTemplate>
      <QuidTitle>{t("internal__transfer__page__title")}</QuidTitle>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormProvider {...methods}>
            <div className={classes.containerInner}>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__head__from__label")}
                </QuidTitle>
                {loading.fromWallet ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  fromWallet?.id === undefined && (
                    <div>
                      <TextField
                        label={t("filter__keyword")}
                        variant="outlined"
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value?.toString()?.length > 3) {
                            setKeyword((prev) => ({
                              ...prev,
                              filter: e.target.value,
                            }));
                          }
                          setKeyword((prev) => ({
                            ...prev,
                            value: e.target.value,
                          }));
                        }}
                        value={keyword?.value}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div className={classes.searchBox}>
                                <SearchIcon />
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {fromWallets?.map(
                        (wallet: WalletDetailResponse, index: number) => (
                          <div
                            key={index}
                            onClick={() => {
                              setFromWallet(wallet);
                            }}
                            className={classes.accountDetailsWrapper}
                          >
                            <div>
                              <Typography variant="body1">
                                <>
                                  {wallet?.customer?.type === "INDIVIDUAL" ? (
                                    <>
                                      {`${wallet?.customer?.name} ${wallet?.customer?.surname}`}
                                    </>
                                  ) : (
                                    <>"n/a</>
                                  )}
                                </>
                                <br />
                                <small>{wallet.walletAddress}</small>
                              </Typography>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
                {fromWallet && (
                  <DetailsBlock label="" toPrint={fromWalletDetails} />
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__head__credit_to__label")}
                </QuidTitle>
                {loading.toBeneficiary ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  fromWallet?.id !== undefined &&
                  toBeneficiary === undefined &&
                  toBeneficiaries?.map(
                    (beneficiary: BeneficiaryDetailResponse, index: number) => (
                      <div
                        key={index}
                        onClick={() => {
                          setBeneficiary(beneficiary);
                        }}
                        className={classes.accountDetailsWrapper}
                      >
                        <div>
                          <Typography variant="body1">
                            {beneficiary.name}
                            <br />
                            <small>{beneficiary.name}</small>
                          </Typography>
                        </div>
                      </div>
                    )
                  )
                )}
                {toBeneficiary && (
                  <DetailsBlock label="" toPrint={toBeneficiaryDetails} />
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__info")}
                </QuidTitle>
                {toBeneficiary && (
                  <>
                    {loading.currencies ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      selectedCurrency === undefined && (
                        <div>
                          {currencies?.map(
                            (
                              currency: BalanceDetailResponse,
                              index: number
                            ) => (
                              <div
                                key={index}
                                onClick={() => {
                                  setSelectedCurrency(currency);
                                }}
                                className={classes.accountDetailsWrapper}
                              >
                                <div>
                                  <Typography variant="body1">
                                    <>{currency?.currency || "n/a"}</>
                                    <br />
                                    <small>
                                      {t("details_cryptoBalanceId")}{" "}
                                      {currency.cryptoBalanceId}
                                    </small>
                                    <br />
                                    <small>
                                      {t("details_smartContractId")}{" "}
                                      {currency.smartContractId}
                                    </small>
                                    <br />
                                    <small>
                                      {t("details_network")} {currency.network}
                                    </small>
                                  </Typography>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}
                    {selectedCurrency && (
                      <>
                        <DetailsBlock label="" toPrint={currencyDetails} />
                        <div className={classes.amountReasonWrapper}>
                          <QuidTextField
                            textFieldProps={{ fullWidth: true }}
                            name="amount"
                            rules={{
                              required: t(
                                "internal__transfer__form__amount__required"
                              ) as string,
                            }}
                            label={t("internal__transfer__form__amount__label")}
                            defaultValues={defaultValues}
                          />
                          <QuidTextField
                            textFieldProps={{ fullWidth: true }}
                            rules={{
                              required: t("reason__required") as string,
                            }}
                            name="reason"
                            label={t(
                              "internal__transfer__form__reference__label"
                            )}
                            defaultValues={defaultValues}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={classes.containerInnerButtons}>
              <Button variant="contained" color="primary" type="submit">
                {t("internal__transfer__create__button")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={onResetForm}
              >
                {t("internal__transfer__reset__button")}
              </Button>
            </div>
          </FormProvider>
        </form>
      </div>
    </MainTemplate>
  );
};

export default CryptoPaymentCreate;
