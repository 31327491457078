import { createSlice } from "@reduxjs/toolkit";
import {
  AccountDataset,
  AccountDatasetCurrency,
} from "entities/accounts/AccountDataset";
import { InvestmentsDataset } from "entities/investments/InvestmentsDatasets";
import { AddressType, AddressUse } from "entities/clients/AddressEntity";
import { CompanyType } from "entities/clients/CompanyEntity";
import { Country } from "entities/clients/CountryEntity";
import { Currency } from "entities/clients/CurrencyEntity";
import { DocumentSubType, DocumentType } from "entities/clients/DocumentEntity";
import {
  FinancialSourceType,
  FinancialCompanySourceType,
  FinancialEmploymentType,
  FinancialEmploymentSector,
} from "entities/clients/FinancialEntity";
import { PersonTitle } from "entities/clients/PersonEntity";

export interface DatasetSliceState {
  fetching: boolean;
  error: Error | null;
  accounts: {
    bankAccountTypes: AccountDataset[];
    identificationTypes: AccountDataset[];
    paymentStatus: AccountDataset[];
    transactionStatus: AccountDataset[];
    accountTypes: AccountDataset[];
    transactionTypes: AccountDataset[];
    transactionInboundTypes: AccountDataset[];
    transactionOutboundTypes: AccountDataset[];
    transactionDirection: AccountDataset[];
    transactionSubtypes: AccountDataset[];
    paymentTypes: AccountDataset[];
    currencies: AccountDatasetCurrency[];
    countries: AccountDataset[];
    legalEntities: AccountDataset[];
    accountStatus: AccountDataset[];
    routingCodeTypes: AccountDataset[];
  };
  parties: {
    countries: Country[];
    currencies: Currency[];
    financialSourceTypes: FinancialSourceType[];
    financialCompanySourceTypes: FinancialCompanySourceType[];
    financialEmploymentTypes: FinancialEmploymentType[];
    financialEmploymentSectors: FinancialEmploymentSector[];
    addressTypes: AddressType[];
    companyTypes: CompanyType[];
    documentTypes: DocumentType[];
    personTitles: PersonTitle[];
    subDocumentTypes: DocumentSubType[];
    addressUses: AddressUse[];
  };
  investments: {
    investmentsStatus: InvestmentsDataset[];
    opportunitiesStatus: InvestmentsDataset[];
  };
}

export const defaultState: DatasetSliceState = {
  error: null,
  fetching: false,
  accounts: {
    bankAccountTypes: [],
    identificationTypes: [],
    paymentStatus: [],
    transactionStatus: [],
    accountTypes: [],
    transactionTypes: [],
    transactionInboundTypes: [],
    transactionOutboundTypes: [],
    transactionDirection: [],
    transactionSubtypes: [],
    paymentTypes: [],
    currencies: [],
    countries: [],
    legalEntities: [],
    accountStatus: [],
    routingCodeTypes: [],
  },
  parties: {
    countries: [],
    currencies: [],
    personTitles: [],
    financialEmploymentTypes: [],
    financialEmploymentSectors: [],
    financialSourceTypes: [],
    financialCompanySourceTypes: [],
    addressTypes: [],
    companyTypes: [],
    addressUses: [],
    documentTypes: [],
    subDocumentTypes: [],
  },
  investments: {
    investmentsStatus: [],
    opportunitiesStatus: [],
  },
};

const datasetSlice = createSlice({
  name: "dataset",
  initialState: defaultState,
  reducers: {},
  extraReducers: {
    "dataset/fetchAllPartiesDataset/pending": (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    "dataset/fetchAllAccountDataset/pending": (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    "dataset/fetchAllInvestmentsDataset/pending": (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    "dataset/fetchAllPartiesDataset/fulfilled": (state, { payload }) => {
      return {
        ...state,
        parties: {
          ...state.parties,
          ...payload,
        },
        fetching: false,
        error: null,
      };
    },
    "dataset/fetchAllAccountDataset/fulfilled": (state, { payload }) => {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          ...payload,
        },
        fetching: false,
        error: null,
      };
    },
    "dataset/fetchAllInvestmentsDataset/fulfilled": (state, { payload }) => {
      return {
        ...state,
        investments: {
          ...state.investments,
          ...payload,
        },
        fetching: false,
        error: null,
      };
    },
    "dataset/fetchAllPartiesDataset/rejected": (state, { payload }) => {
      return {
        ...state,
        error: payload,
        fetching: false,
      };
    },
    "dataset/fetchAllAccountDataset/rejected": (state, { payload }) => {
      return {
        ...state,
        error: payload,
        fetching: false,
      };
    },
    "dataset/fetchAllInvestmentsDataset/rejected": (state, { payload }) => {
      return {
        ...state,
        error: payload,
        fetching: false,
      };
    },
  },
});

export default datasetSlice;

export const getPartiesCountries = (state: DatasetSliceState): Country[] =>
  state?.parties?.countries;

export const getPartiesCurrencies = (state: DatasetSliceState): Currency[] =>
  state?.parties?.currencies;

export const getPartiesFinancialEmploymentTypes = (
  state: DatasetSliceState
): FinancialEmploymentType[] => state?.parties?.financialEmploymentTypes;

export const getPartiesFinancialEmploymentSectors = (
  state: DatasetSliceState
): FinancialEmploymentSector[] => state?.parties?.financialEmploymentSectors;

export const getPartiesFinancialSourceTypes = (
  state: DatasetSliceState
): FinancialSourceType[] => state?.parties?.financialSourceTypes;

export const getPartiesFinancialCompanySourceTypes = (
  state: DatasetSliceState
): FinancialCompanySourceType[] => state?.parties?.financialCompanySourceTypes;

export const getPartiesAddressTypes = (
  state: DatasetSliceState
): AddressType[] => state?.parties?.addressTypes;

export const getCompanyTypes = (state: DatasetSliceState): CompanyType[] =>
  state?.parties?.companyTypes;

export const getPartiesAddressUses = (state: DatasetSliceState): AddressUse[] =>
  state?.parties?.addressUses;

export const getPartiesDocumentTypes = (state: DatasetSliceState) =>
  state?.parties?.documentTypes;

export const getPartiesDocumentSubTypes = (state: DatasetSliceState) =>
  state?.parties?.subDocumentTypes;

export const getPartiesPersonTitles = (state: DatasetSliceState) =>
  state?.parties?.personTitles;

export const getAccountsBankAccountTypes = (state: DatasetSliceState) =>
  state?.accounts?.bankAccountTypes;

export const getAccountsIdentificationTypes = (state: DatasetSliceState) =>
  state?.accounts?.identificationTypes;

export const getAccountsPaymentStatus = (state: DatasetSliceState) =>
  state?.accounts?.paymentStatus;

export const getAccountsTransactionStatus = (state: DatasetSliceState) =>
  state?.accounts?.transactionStatus;

export const getAccountTypes = (state: DatasetSliceState) =>
  state?.accounts?.accountTypes;

export const getAccountsTransactionTypes = (state: DatasetSliceState) =>
  state?.accounts?.transactionTypes;

export const getAccountsTransactionInboundTypes = (state: DatasetSliceState) =>
  state?.accounts?.transactionInboundTypes;

export const getAccountsTransactionOutboundTypes = (state: DatasetSliceState) =>
  state?.accounts?.transactionOutboundTypes;

export const getAccountsTransactionDirection = (state: DatasetSliceState) =>
  state?.accounts?.transactionDirection;

export const getAccountsTransactionSubtypes = (state: DatasetSliceState) =>
  state?.accounts?.transactionSubtypes;

export const getAccountsPaymentTypes = (state: DatasetSliceState) =>
  state?.accounts?.paymentTypes;

export const getAccountsCurrencies = (state: DatasetSliceState) =>
  state?.accounts?.currencies;

export const getAccountsCountries = (state: DatasetSliceState) =>
  state?.accounts?.countries;

export const getAccountsLegalEntities = (state: DatasetSliceState) =>
  state?.accounts?.legalEntities;

export const getAccountStatus = (state: DatasetSliceState) =>
  state?.accounts?.accountStatus;

export const getAccountsRoutingCodeTypes = (state: DatasetSliceState) =>
  state?.accounts?.routingCodeTypes;

export const getInvestmentsInvestmentsStatus = (state: DatasetSliceState) =>
  state?.investments?.investmentsStatus;

export const getInvestmentsOpportunitiesStatus = (state: DatasetSliceState) =>
  state?.investments?.opportunitiesStatus;
