import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import MainTemplate from "templates/MainTemplate";
import { useTranslation } from "react-i18next";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { CellParams, ColDef, PageChangeParams } from "@material-ui/data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsCurrencies,
  getAccountsTransactionStatus,
} from "store/reducers/app.reducer";
import SearchIcon from "components/atoms/icons/SearchIcon";
import DetailIcon from "components/atoms/icons/DetailIcon";
import QuidTitle from "components/atoms/QuidTitle";
import StatusBadge from "components/atoms/StatusBadge";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import {
  AccountDataset,
  AccountDatasetCurrency,
} from "entities/accounts/AccountDataset";
import { AcquiringPayment } from "entities/accounts/Transaction";
import { PartiesPagination } from "entities/accounts/Pagination";
import { formatDate } from "utils";
import {
  getAcquiringPaymentByPartyId,
  getAcquiringPayments,
} from "api/payments";
import { uniqueId } from "lodash";
import AcquiringPaymentDetailModal from "components/organisms/payments/AcquiringPaymentDetailModal";
import { RouteComponentProps } from "react-router";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  paymentsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flexTextFields: {
    flex: 1,
  },
  flexSpace: {
    flex: 0.1,
  },
  flexCsv: {
    flex: 0.1,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  detailLink: {
    color: theme.palette.secondary.main,
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  status: {
    display: "flex",
    flexDirection: "column",
    lineheiht: "20px",
  },
  type: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
}));

const PaymentsAcquiring: React.FC<RouteComponentProps<{ partyId: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const partyId: string = (+match.params.partyId as unknown) as string;
  const { t } = useTranslation(["payments", "account"]);
  const [pagination, setPagination] = useState({} as PartiesPagination);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<AcquiringPayment[]>([]);
  const [pageNo, setPageNo] = useState(0);
  const [modalOpen, setModalOpen] = useState<boolean | undefined>();
  const [transactionDetail, setTransactionDetail] = useState<
    AcquiringPayment | undefined
  >();
  const [filter, setFilter] = useState({
    search: "",
    from: "",
    to: "",
    currency: "",
    status: "",
  });

  const currencies = useSelector(getAccountsCurrencies);
  const paymentStatuses = useSelector(getAccountsTransactionStatus);

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 240,
    },
    {
      field: "status",
      width: 320,
      headerName: t("account:transactionTable__header_name__status"),
      renderCell: (params) => (
        <div className={classes.status}>
          <StatusBadge status={params?.row?.status} />
        </div>
      ),
    },
    {
      field: "walletId",
      width: 280,
      headerName: t("account:transactionTable__header_name__walletId"),
      renderCell: (params) => (
        <div className={classes.type}>{params?.row?.walletId}</div>
      ),
    },
    {
      field: "created",
      headerName: t("account:transactionTable__header_name__createdAt"),
      type: "date",
      width: 240,
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.created, "dd/MM/yyyy")}
          <small>{formatDate(params.row?.created, "HH:mm:ss")}</small>
        </div>
      ),
    },
    {
      field: "updated",
      headerName: t("account:transactionTable__header_name__updatedAt"),
      type: "date",
      width: 240,
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.updated, "dd/MM/yyyy")}
          <small>{formatDate(params.row?.updated, "HH:mm:ss")}</small>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: t("account:transactionTable__header_name__amount"),
      width: 150,
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(params?.row?.amount, params?.row?.currency),
    },
    {
      field: "receipt",
      width: 100,
      headerName: " ",
      align: "right",
      renderCell: (params) => {
        const transaction = (params?.row as unknown) as AcquiringPayment;
        if (!transaction.paymentSessionId) {
          return <></>;
        }
        return (
          <Button
            onClick={() => {
              setTransactionDetail({
                paymentSessionId: params?.row?.paymentSessionId || "n/a",
                status: params?.row?.status || "n/a",
                walletId: params?.row?.walletId || "n/a",
                currency: params?.row?.currency || "n/a",
                amount: params?.row?.amount || 0,
                created: params?.row?.created || "n/a",
                updated: params?.row?.updated || "n/a",
                reason: params?.row?.reason || "n/a",
                toonieFees: params?.row?.toonieFees || "n/a",
              });
              setModalOpen(true);
            }}
          >
            <DetailIcon />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchIncomingTransactions = async (): Promise<void> => {
      setLoading(true);
      try {
        if (partyId) {
          const res = await getAcquiringPaymentByPartyId({
            ...(filter?.search && { keyword: filter?.search }),
            ...(filter?.currency && { currency: filter?.currency }),
            ...(filter?.status && { status: filter?.status }),
            ...(filter?.from && { date_from: filter?.from }),
            ...(filter?.to && { date_to: filter?.to }),
            page: pageNo,
            size: TABLE_PAGE_SIZE,
            partyId,
          });
          setPagination(res.pagination);
          setTransactions(
            res?.payments?.map((props) => {
              return { ...props, id: props.paymentSessionId || uniqueId() };
            })
          );
        } else {
          const res = await getAcquiringPayments({
            ...(filter?.search && { keyword: filter?.search }),
            ...(filter?.currency && { currency: filter?.currency }),
            ...(filter?.status && { status: filter?.status }),
            ...(filter?.from && { date_from: filter?.from }),
            ...(filter?.to && { date_to: filter?.to }),
            page: pageNo,
            size: TABLE_PAGE_SIZE,
          });
          setPagination(res.pagination);
          setTransactions(
            res.payments.map((props) => {
              return { ...props, id: props.paymentSessionId || uniqueId() };
            })
          );
        }
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void fetchIncomingTransactions();
  }, [
    pageNo,
    filter?.search,
    filter?.currency,
    filter?.status,
    filter?.from,
    filter?.to,
  ]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const closeAndResetModal = () => {
    setModalOpen(false);
    setTransactionDetail(undefined);
  };

  return (
    <MainTemplate>
      <AcquiringPaymentDetailModal
        open={modalOpen}
        setOpen={closeAndResetModal}
        payment={transactionDetail}
      />
      <div className={classes.paymentsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>
              {t("incoming__payment__page_acquiring_payments__title")}
            </QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.filterContainer}>
          <div className={classes.flexTextFields}>
            <TextField
              label={t("filter__search")}
              variant="outlined"
              fullWidth
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({ ...state, search: e.target.value }))
              }
              value={filter.search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div className={classes.searchBox}>
                      <SearchIcon color="#ffffff" />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.flexSpace}></div>
          <div className={classes.flexTextFields}>
            <TextField
              label={t("outgoing__payment__page__add__status")}
              variant="outlined"
              select
              fullWidth
              value={filter.status}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({
                  ...state,
                  status: e.target.value,
                }))
              }
            >
              <MenuItem value="">
                {t("outgoing__payment__page__add__status")}
              </MenuItem>
              {paymentStatuses?.map((option: AccountDataset, index: number) => (
                <MenuItem key={`${option}-${index}`} value={option.code}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.flexSpace}></div>
          <div className={classes.flexTextFields}>
            <TextField
              label={t("filter__from")}
              variant="outlined"
              type="date"
              fullWidth
              value={filter.from}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({ ...state, from: e.target.value }))
              }
            />
          </div>
          <div className={classes.flexSpace}></div>
          <div className={classes.flexTextFields}>
            <TextField
              label={t("filter__to")}
              variant="outlined"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={filter.to}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({ ...state, to: e.target.value }))
              }
            />
          </div>
          <div className={classes.flexSpace}></div>
          <div className={classes.flexTextFields}>
            <TextField
              label={t("filter__currency")}
              variant="outlined"
              select
              fullWidth
              value={filter.currency}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilter((state) => ({ ...state, currency: e.target.value }))
              }
            >
              <MenuItem value="">{t("filter__currency")}</MenuItem>
              {currencies?.map(
                (currency: AccountDatasetCurrency, index: number) => (
                  <MenuItem
                    key={`${currency.code}-${index}`}
                    value={currency.code}
                  >
                    {currency?.text}
                  </MenuItem>
                )
              )}
            </TextField>
          </div>
        </div>
        <QuidDataGrid
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={transactions}
          rowCount={pagination?.totalEntries}
        />
      </div>
    </MainTemplate>
  );
};

export default PaymentsAcquiring;
