import { Grid, makeStyles, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import { handleFailure } from "resHandlers";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import clsx from "clsx";
import {
  activateProgramById,
  deactivateProgramById,
  fetchCardsProgramDetailsById,
} from "api/cards";
import { ProgramDetailsResponse } from "entities/cards/CardEntity";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";

const useStyles = makeStyles(() => ({
  programContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
  },
  actionButtons: {
    marginTop: 16,
    marginBottom: 16,
    display: "flex",
    gap: 8,
    width: "100%",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  detailContainer: {
    background: "#FAFAFA",
    width: "100%",
  },
  pl24: {
    paddingLeft: 24,
  },
  pr24: {
    paddingRight: 24,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
  redButton: {
    backgroundColor: "#FF6F0F",
  },
  greenButton: {
    backgroundColor: "#A1F714",
  },
}));

const GiftCardsProgramDetails: React.FC<
  RouteComponentProps<{ id: string }>
> = ({ match }) => {
  const { t } = useTranslation("cards");

  const dispatch = useDispatch();

  const classes = useStyles();
  const id = parseInt(match.params.id, 10);

  const [programDetails, setProgramDetails] = useState(
    {} as ProgramDetailsResponse
  );

  const margins = clsx(classes.pl24, classes.pr24, classes.mt32, classes.mb32);

  const cardDetailsToPrint: ProgramDetailsResponse = {
    id: programDetails?.id || "n/a",
    name: programDetails?.name || "n/a",
    logo_name: programDetails?.logo_name || "n/a",
    action: programDetails?.action || "n/a",
    description: programDetails?.description || "n/a",
    value: programDetails?.value || "n/a",
    active: programDetails?.active?.toString() || "n/a",
  };

  const fetchProgramsDetails = async () => {
    try {
      const res = await fetchCardsProgramDetailsById({ id });

      setProgramDetails(res);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    fetchProgramsDetails();
  }, []);

  const deactivateProgram = async () => {
    try {
      await deactivateProgramById({ id });
      await fetchProgramsDetails();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const activateProgram = async () => {
    try {
      await activateProgramById({ id });
      await fetchProgramsDetails();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  return (
    <MainTemplate>
      <div className={classes.programContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>
              {t("gift_card__program_details__page__title")}
            </QuidTitle>
          </Grid>
        </Grid>
        <Grid container>
          <div className={classes.actionButtons}>
            <Button
              onClick={activateProgram}
              variant="contained"
              className={classes.greenButton}
              disabled={programDetails?.active === true}
            >
              {t("button__activate")}
            </Button>
            <Button
              onClick={deactivateProgram}
              variant="contained"
              className={classes.redButton}
              disabled={programDetails?.active === false}
            >
              {t("button__deactivate")}
            </Button>
          </div>
        </Grid>
        <div className={classes.detailContainer}>
          <Grid container className={margins} justify={"center"}>
            <Grid item xs={6}>
              <DetailsBlock
                toPrint={cardDetailsToPrint}
                label={t("card__details__title")}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </MainTemplate>
  );
};

export default GiftCardsProgramDetails;
