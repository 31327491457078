import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import { Report } from "entities/clients/Investements";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { createInvestmentRoi, updateInvestmentRoi } from "api/investments";
import FullScreenModal from "components/atoms/FullScreenModal";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  gridContainerInfo: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,

    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
  },
  form: {
    margin: "0px auto",
  },
}));

type FormValues = {
  investAmount: number;
  reportDate: string;
  investDate: string;
  investmentId: number;
  realized: boolean | string;
  roiPercent: number;
};

interface ModalProps {
  open: boolean | undefined;
  setOpen: (open: boolean) => void;
  data?: Report;
  investmentId: number;
}

const RoiCreateOrUpdate: React.FC<ModalProps> = ({
  setOpen,
  open,
  data,
  investmentId,
}) => {
  const { t } = useTranslation("investments");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [realized, setRealized] = useState("false");
  const roi = data;
  const roiId: number = data?.id || 0;

  const defaultValues: FormValues = {
    investAmount: 0,
    reportDate: "",
    investDate: "",
    investmentId,
    realized: "",
    roiPercent: 0,
  };

  const realizedValues = [
    { text: "--", value: "" },
    { text: "true", value: "true" },
    { text: "false", value: "false" },
  ];

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onFormSubmit = async (values: Report) => {
    setIsLoading(true);
    try {
      const transformedValues = {
        ...values,
        investmentId,
        investAmount: Number(values.investAmount),
        roiPercent: Number(values.roiPercent),
        realized: Boolean(realized),
        reportDate: new Date(values.reportDate).toISOString(),
        investDate: new Date(values.investDate).toISOString(),
      };

      if (roiId) {
        await updateInvestmentRoi(roiId, transformedValues);
      } else {
        await createInvestmentRoi(transformedValues);
      }
      setOpen(false);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    resetValues();
  }, [dispatch, roiId]);

  const resetValues = () => {
    let isMounted = true;

    if (isMounted) {
      reset(({
        investAmount: roi?.investAmount,
        investDate: roi?.investDate
          ? format(new Date(roi?.investDate), "yyyy-MM-dd")
          : "",
        reportDate: roi?.reportDate
          ? format(new Date(roi?.reportDate), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        investmentId: roi?.investmentId,
        realized: (roi?.realized ?? "").toString(),
        roiPercent: roi?.roiPercent,
      } as unknown) as FormValues);
    }

    return (): void => {
      isMounted = false;
    };
  };

  const onResetAndCloseModal = () => {
    setOpen(false);
  };

  return (
    <FullScreenModal open={open} onClose={onResetAndCloseModal}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)} className={classes.form}>
          <Grid
            container
            spacing={1}
            direction="row"
            className={classes.gridContainerInfo}
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t("news__section_title__roi_details")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="investAmount"
                type="number"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__investAmount__required"
                  ) as string,
                }}
                label={t("details__label__investAmount")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="realized"
                label={t("details__label__realized")}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                rules={{
                  required: t("details__label__realized_required") as boolean,
                }}
                defaultValues={defaultValues}
              >
                {realizedValues?.map((value, index: number) => (
                  <MenuItem
                    key={index}
                    value={value.text}
                    onClick={() => setRealized(value.value)}
                  >
                    {value.text}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="reportDate"
                textFieldProps={{
                  type: "date",
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                }}
                rules={{
                  required: t("details__label__reportDate_required") as string,
                }}
                label={t("details__label__reportDate")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="investDate"
                textFieldProps={{
                  type: "date",
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                }}
                rules={{
                  required: t("details__label__investDatee_required") as string,
                }}
                label={t("details__label__investDate")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="roiPercent"
                textFieldProps={{
                  fullWidth: true,
                }}
                type="number"
                rules={{
                  required: t("details__label__percent_required") as string,
                }}
                defaultValues={defaultValues}
                label={t("details__label__roiPercent")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.updateBtn}
                variant="contained"
                color="primary"
                disabled={isLoading}
                type="submit"
              >
                {t("label_button_save")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FullScreenModal>
  );
};

export default RoiCreateOrUpdate;
