import { makeStyles, Button } from "@material-ui/core";
import { ColDef } from "@material-ui/data-grid";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import QuidTitle from "components/atoms/QuidTitle";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import {
  approveBulkTransfer,
  fetchBulkTransferByTransferId,
  rejectBulkTransfer,
} from "api/accounts";
import { formatDate } from "utils";
import { useParams } from "react-router";
import {
  BulkTransferDetailResponse,
  BulkTransferGeneralInfo,
} from "entities/accounts/Account";
import BulkDetailModal from "components/organisms/payments/BulkDetailModal";
import DetailIcon from "components/atoms/icons/DetailIcon";
import { handleSuccessfulMessage, handleFailure } from "resHandlers";
import ApproveRejectButtons from "components/atoms/ApproveRejectButtons";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";

const useStyles = makeStyles(() => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  container: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  thridCol: {
    width: "33%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flexCsv: {
    flex: 0.1,
    marginLeft: "auto",
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      color: "#000",
    },
  },
}));

const PaymentsInternalBulkDetails: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id: any = useParams();
  const { t } = useTranslation("payments");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState<any>({
    data: {},
    isOpen: false,
  });
  const [disabledActions, setDisabledActions] = useState<boolean>(false);
  const [
    bulkTransferDetails,
    setBulkTransferDetails,
  ] = useState<BulkTransferDetailResponse>();

  const bulkStatus = bulkTransferDetails?.status;

  const generalInfo: BulkTransferGeneralInfo = {
    id: bulkTransferDetails?.id?.toString() || "n/a",
    status: bulkStatus || "n/a",
    note: bulkTransferDetails?.note || "n/a",
    created_at:
      formatDate(bulkTransferDetails?.created_at, "dd/MM/yyyy HH:mm:ss") ||
      "n/a",
  };

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", width: 75 },
    {
      flex: 1,
      field: "source",
      headerName: t("bulkTable__header_name__source"),
      renderCell: (params) => {
        const accountName = params?.row?.source_balance?.account?.name || "n/a";
        return (
          <div>
            {params.row?.source_balance?.wallet_id || "n/a"}{" "}
            <small>({accountName})</small>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "destination",
      headerName: t("bulkTable__header_name__destination"),
      renderCell: (params) => {
        const accountName =
          params?.row?.destination_balance?.account?.name || "n/a";
        return (
          <div>
            {params.row?.destination_balance?.wallet_id || "n/a"}{" "}
            <small>({accountName})</small>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "amount",
      headerName: t("bulkTable__header_name__amount"),
      renderCell: (params) => {
        return (
          MoneyFormatter(params.row?.amount, params.row?.currency) || "n/a"
        );
      },
    },
    {
      flex: 1,
      field: "reason",
      headerName: t("bulkTable__header_name__reason"),
      renderCell: (params) => {
        return params.row?.reason || "n/a";
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: t("bulkTable__header_name__status"),
      renderCell: (params) => {
        return params.row?.status || "n/a";
      },
    },
    {
      field: "",
      headerName: t("bulkTable__header_name__details"),
      renderCell: (params) => {
        if (!params?.row?.id) {
          return <></>;
        }
        return (
          <Button onClick={() => setModal({ isOpen: true, data: params?.row })}>
            <DetailIcon />
          </Button>
        );
      },
    },
  ];

  const fetchDetails = useCallback(async () => {
    try {
      const res = await fetchBulkTransferByTransferId({ id: id?.bulkId });
      setBulkTransferDetails(res);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  }, [id.bulkId]);

  useEffect(() => {
    void fetchDetails();
  }, []);

  const onAccept = async () => {
    setDisabledActions(true);
    try {
      await approveBulkTransfer({
        id: id.bulkId,
      });
      await fetchDetails();
      handleSuccessfulMessage("Action received");
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setDisabledActions(false);
    }
  };

  const onReject = async () => {
    setDisabledActions(true);
    try {
      await rejectBulkTransfer({
        id: id.bulkId,
      });
      await fetchDetails();
      handleSuccessfulMessage("Action received");
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setDisabledActions(false);
    }
  };

  return (
    <>
      <BulkDetailModal
        open={modal?.isOpen}
        setOpen={(e) => setModal({ isOpen: e })}
        data={modal?.data}
      />
      <MainTemplate>
        <div className={classes.container}>
          <QuidTitle>{t("bulk__details__title")}</QuidTitle>
          <div className={classes.thridCol}>
            <DetailsBlock
              toPrint={generalInfo}
              label="details__bulk_transfer__general_info"
            />
          </div>
          {bulkStatus && bulkStatus?.toLowerCase() === "pending" && (
            <ApproveRejectButtons
              onAccept={onAccept}
              onReject={onReject}
              disabled={disabledActions}
            />
          )}
        </div>
        <div className={classes.mt35}>
          <QuidTitle>{t("bulk_transfersTable__title")}</QuidTitle>
          {bulkTransferDetails?.transfers && (
            <QuidDataGrid
              sortModel={[{ field: "id", sort: "desc" }]}
              loading={loading}
              columns={columns}
              rows={bulkTransferDetails?.transfers}
            />
          )}
        </div>
      </MainTemplate>
    </>
  );
};

export default PaymentsInternalBulkDetails;
