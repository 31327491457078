import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainTemplate from "templates/MainTemplate";
import { useTranslation } from "react-i18next";
import QuidTitle from "components/atoms/QuidTitle";
import { RouteComponentProps } from "react-router";
import {
  Detail,
  KeyFact,
  Opportunity,
} from "entities/opportunities/opportunityEntity";
import OpportunitiesCreateOrEdit from "components/organisms/opportunities/OpportunitiesCreateOrEdit";
import {
  fetchOpportunityById,
  updateOpportunity,
  updateOpportunityAPY,
} from "api/opportunities";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
}));

type FormValues = {
  name: string;
  currency: string;
  assetClass: string;
  minRequested: string;
  maxRequested: string;
  network: string;
  horizon: string;
  stage: string;
  sector: string;
  tokenPrice: string;
  valuation: string;
  shortDescription: string;
  longDescription: string;
  startDate: Date;
  endDate: Date;
  noToken: string;
  bigImageId: number;
  logoImageId: number;
  details: Detail[];
  keyFacts: KeyFact[];
  beneficiaryWalletsWalletId: string;
  beneficiaryWalletsCurrency: string;
  smartContractName: string;
  smartContractTemplate: string;
  smartContractAmount: string;
  smartContractDescription: string;
  smartContractPrecision: number;
  smartContractAddress: string;
  smartContractNetwork: string;
  step: string;
  streamableWalletAddress?: string;
  streamableCurrency?: string;
  streamableSetupType?: string;
  streamableSetupApy?: string;
  streamableSetupInvestment?: string;
  streamableSetupDuration?: string;
  referralPercentage?: string;
};

const OpportunityDetailsEdit: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("opportunities");
  const classes = useStyles();
  const dispatch = useDispatch();
  const opportunityId: number = +match.params.id;
  const [isLoading, setLoading] = useState(false);
  const [details, setDetails] = useState<Opportunity>();

  useEffect(() => {
    if (opportunityId) {
      void getOpportunityDetails(opportunityId);
    }
  }, [dispatch, opportunityId]);

  const getOpportunityDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const opportunityDetails = await fetchOpportunityById({
          id: id as number,
        });
        setDetails(opportunityDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  const onFormSubmit = async (values: FormValues) => {
    let opportunitySetup = null;
    let opportunityInvestmentSetupRequest = null;

    if (opportunityId) {
      setLoading(true);

      if (
        values?.streamableSetupType &&
        values?.streamableSetupApy &&
        values?.streamableSetupInvestment
      ) {
        opportunitySetup = {
          type: values.streamableSetupType,
          apy: values.streamableSetupApy,
          investment: values.streamableSetupInvestment,
          duration: values?.streamableSetupDuration ?? "0",
        };
        opportunityInvestmentSetupRequest = {
          type: values.streamableSetupType,
          apy: values.streamableSetupApy,
          investment: values.streamableSetupInvestment,
          duration: values?.streamableSetupDuration ?? "0",
        };
      } else {
        opportunitySetup = null;
        opportunityInvestmentSetupRequest = null;
      }

      try {
        await updateOpportunity({
          opportunityId,
          request: {
            name: values?.name,
            currency: values?.currency,
            assetClass: values?.assetClass,
            minRequested: values?.minRequested,
            maxRequested: values?.maxRequested,
            network: values?.network,
            horizon: values?.horizon,
            stage: values?.stage,
            sector: values?.sector,
            referralPercentage: values?.referralPercentage,
            tokenPrice: values?.tokenPrice,
            valuation: values?.valuation,
            shortDescription: values?.shortDescription,
            longDescription: values?.longDescription,
            noToken: parseInt(values?.noToken, 10),
            bigImageId: 0,
            logoImageId: 0,
            details: values.details || [],
            keyFacts: values?.keyFacts || [],
            step: values?.step,
            smartContract: values?.smartContractName
              ? {
                  name: values?.smartContractName,
                  template: values?.smartContractTemplate,
                  amount: values?.smartContractAmount,
                  description: values?.smartContractDescription,
                  precision: values?.smartContractName
                    ? parseInt(values?.smartContractPrecision?.toString(), 10)
                    : undefined,
                  uintAmount: values?.smartContractName ? 1 : undefined,
                }
              : values?.smartContractAddress
              ? {
                  address: values?.smartContractAddress,
                  network: values?.smartContractNetwork,
                }
              : undefined,
            beneficiaryWallets: {
              wallets: [
                {
                  currency: values?.beneficiaryWalletsCurrency,
                  walletId: values?.beneficiaryWalletsWalletId,
                },
              ],
            },
            startDate: `${values?.startDate}T00:00:00.000Z`,
            endDate: `${values?.endDate}T00:00:00.000Z`,
            supportsPartial: false,
            opportunityInvestmentSetupRequest,
            opportunitySetup,
            streamableWalletAddress: values.streamableWalletAddress,
            streamableCurrency: values.streamableCurrency,
          },
        });

        if (
          values?.streamableSetupApy &&
          values?.streamableSetupApy?.toString() !==
            details?.opportunitySetup?.apy?.toString()
        ) {
          await updateOpportunityAPY({
            opportunityId,
            apy: values.streamableSetupApy,
          });
        }
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: t("opportunity__updated__success__message"),
            type: "success",
          })
        );
        getOpportunityDetails(opportunityId);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <MainTemplate>
      <div className={classes.root}>
        <QuidTitle>{t("opportunity__details__header_name__tab")}</QuidTitle>
        <OpportunitiesCreateOrEdit
          opportunityId={opportunityId}
          opportunity={details}
          mode={"update"}
          isLoading={isLoading}
          onFormSubmit={onFormSubmit}
        />
      </div>
    </MainTemplate>
  );
};

export default OpportunityDetailsEdit;
