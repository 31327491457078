import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ApproveRejectBox from "../../atoms/ApproveRejectBox";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import VerificationTemplate from "templates/VerificationTemplate";
import { useSelector } from "react-redux";
import { getPartiesCountries } from "store/reducers/app.reducer";
import AttachmentWithoutPreview from "components/molecules/verifications/AttachmentWithoutPreview";
import jwt_decode from "jwt-decode";
import { formatDate } from "utils";
import {
  acceptTask,
  rejectTask,
  updateDocument,
  updateDocumentDetails,
} from "api/tasks";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { handleFailure } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";
import {
  fetchAccountByAccountId,
  getAllProviders,
  getVirtualIbanByAccountId,
  putAccountProvider,
  putVirtualIban,
} from "api/accounts";
import { useTranslation } from "react-i18next";
import { AccountResponse } from "entities/accounts/Account";
import IdentityDocBlock from "components/molecules/verifications/IdentityDocBlock";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
  ibanWrapper: {
    marginTop: 16,
  },
}));

export interface IndividualDetails {
  [key: string]: string;
}

export interface QuidAccountInformationInterface {
  quidReference: string;
  currency: string;
  account_type: string;
}

export interface PayloadUpdateProvider {
  provider: string;
}

const CheckProviderDetails: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const { t } = useTranslation("validations");

  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();

  const [iban, setIban] = useState<string>("");
  const [provider, setProvider] = useState<string>("");
  const [allProviders, setAllProviders] = useState<string[]>([]);
  const [accountDetails, setAccountDetails] = useState({} as AccountResponse);

  const accountId = task?.variables?.accountId || -1;
  const customerDetail = task?.variables?.customer;
  const customerAddressDetail = task?.variables?.address;
  const taskDocumentAccountUsage = task?.documents.find(
    (d) => d.type === "ACCOUNT_USAGE"
  );
  const idDocuments = task?.documents?.filter((d) => d.type === "ID_DOCUMENT");
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const correctRequirementCheck = {
    ALL_IS_OK: false,
  };

  const fetchAllProviders = useCallback(async ({ ignore }): Promise<void> => {
    try {
      if (!ignore) {
        const res = await getAllProviders();
        setAllProviders(res);
      }
    } catch (e: any) {
      handleFailure(e);
    }
  }, []);

  const fetchAccountDetails = useCallback(
    async ({ ignore, accountId }): Promise<void> => {
      try {
        if (!ignore && accountId !== -1) {
          const res = await fetchAccountByAccountId({ accountId });
          setAccountDetails(res);
        }
      } catch (e: any) {
        handleFailure(e);
      }
    },
    []
  );

  const fetchVirtualIbanByAccountId = useCallback(
    async ({ ignore, accountId }): Promise<void> => {
      try {
        if (!ignore && accountId !== -1) {
          const res = await getVirtualIbanByAccountId({
            account_id: accountId,
          });
          setIban(res);
        }
      } catch (e: any) {
        handleFailure(e);
      }
    },
    []
  );

  const updateAccuntProvider = async (
    accountId: number,
    values: PayloadUpdateProvider
  ) => {
    try {
      await putAccountProvider(accountId, values);
      await fetchAccountDetails({ ignore: false, accountId });
    } catch (e: any) {
      handleFailure(e);
    }
  };

  useEffect(() => {
    let ignore = false;

    void fetchAccountDetails({ ignore, accountId });
    void fetchVirtualIbanByAccountId({ ignore, accountId });
    return () => {
      ignore = true;
    };
  }, [accountId]);

  useEffect(() => {
    let ignore = false;

    void fetchAllProviders({ ignore });
    return () => {
      ignore = true;
    };
  }, []);

  if (!task) {
    return null;
  }

  const customerToPrint: IndividualDetails = {
    userId: customerDetail?.id,
    name: customerDetail?.name,
    surname: customerDetail?.surname,
    dateOfBirth: formatDate(customerDetail?.birthDate),
    countryOfBirth: getCountry(customerDetail?.countryOfBirth),
    nationality: getCountry(customerDetail?.nationality),
    "CONTACT INFORMATIONS": "",
    phone: `${customerDetail?.prefix} ${customerDetail?.mobileNumber}`,
    email: customerDetail?.email,
    "OTHER INFORMATIONS": "",
    mobileNumberVerified: customerDetail?.mobileNumberVerified ? "Yes" : "No",
    emailVerified: customerDetail?.emailVerified ? "Yes" : "No",
    ADDRESS: "",
    addressLine1: customerAddressDetail?.addressLine1,
    addressLine2: customerAddressDetail?.addressLine2,
    city: customerAddressDetail?.city,
    state: customerAddressDetail?.state || "",
    country: getCountry(customerAddressDetail?.country) || "",
    postalCode: customerAddressDetail?.postalCode,
  };

  const handleApproveTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const putIban = async () => {
    try {
      if (accountId) {
        await putVirtualIban({ account_id: accountId, iban });
        await fetchVirtualIbanByAccountId({ ignore: false, accountId });
      }
    } catch (e: any) {
      handleFailure(e);
    }
  };

  const onSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedProvider: string = e.target.value;
    let payload: PayloadUpdateProvider = {
      provider: "",
    };

    if (selectedProvider && accountId) {
      payload = {
        provider: selectedProvider,
      };
      setProvider(selectedProvider);
      updateAccuntProvider(accountId, payload);
    }
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      task={task}
      columns={columns}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {customerToPrint && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={customerToPrint}
              label="individual__details__title"
            />
          </div>
        )}
        <div className={classes.detailsWrapperThird}>
          {idDocuments && idDocuments?.length > 0 && (
            <IdentityDocBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              updateDoc={(params) => updateDocument({ ...params, taskKey })}
              updateDocDetails={(params) =>
                updateDocumentDetails({ ...params, taskKey })
              }
              refetch={refetch}
              docs={idDocuments}
              taskId={taskId}
              isAssignedToUser={isAssignedToUser}
            />
          )}
        </div>
        <div className={classes.detailsWrapperThird}>
          <TextField
            name="currentProvider"
            label={t("all_providers")}
            variant="outlined"
            select
            fullWidth
            value={
              accountDetails?.provider && provider?.length === 0
                ? accountDetails?.provider
                : provider
            }
            onChange={onSelectChange}
          >
            <MenuItem value="">{t("all_providers")}</MenuItem>
            {allProviders?.map((option: string, index: number) => (
              <MenuItem key={`${option}-${index}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <div className={classes.ibanWrapper}>
            {(
              accountDetails?.provider && provider?.length === 0
                ? accountDetails?.provider?.toLowerCase() ===
                  "clearjunction_manual"
                : provider?.toLowerCase() === "clearjunction_manual"
            ) ? (
              <TextField
                label={t("iban")}
                variant="outlined"
                fullWidth
                value={iban}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setIban(e.target.value)
                }
                onBlur={() => putIban()}
              />
            ) : null}
          </div>
        </div>
        <div className={classes.detailsWrapperThird}>
          {taskDocumentAccountUsage && (
            <div>
              <DetailsBlock label="account__supporting__document" />
              <AttachmentWithoutPreview
                attachment={taskDocumentAccountUsage}
                downloadDoc={(params) =>
                  isHistory === undefined
                    ? fetchDocument({ ...params, taskId, taskKey })
                    : fetchHistoricalDocument({ ...params, taskId, taskKey })
                }
              />
            </div>
          )}
        </div>
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            task={task}
            docs={idDocuments}
            checks={correctRequirementCheck}
            accept={(request) => handleApproveTask(request)}
            reject={(request) => handleRejectTask(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task?.checks}
          status={task?.result?.status}
          reason={task?.result?.reason}
          description={task?.result?.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default CheckProviderDetails;
