import React, { ChangeEvent, useEffect, useState } from "react";
import { Lock, LockOpen } from "@material-ui/icons";
import {
  Grid,
  MenuItem,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { ColDef, CellParams, PageChangeParams } from "@material-ui/data-grid";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidTitle from "components/atoms/QuidTitle";
import DetailButton from "components/atoms/DetailButton";
import { fetchCustomerList } from "api/customers";
import { PartiesPagination } from "entities/accounts/Pagination";
import { CustomerBackoffice } from "entities/clients/CustomerBackofficeEntity";
import CsvIcon from "components/atoms/icons/CsvIcon";
import useCsvDownloadHandler from "shared/hooks/useCsvDownloadHandler";
import { downloadUsersCsv } from "services/users";
import Loader from "components/atoms/icons/Loader";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  watchIcon: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "#37383C",
  },
  customerContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  circle: {
    backgroundColor: theme.palette.secondary.main,
    width: "8px",
    height: "8px",
    borderRadius: "30px",
    marginLeft: "4px",
    transform: "rotate(-180deg)",
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  statusCircle: {
    width: "14px",
    height: "14px",
    marginLeft: "8px",
    borderRadius: "30px",
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
  },
}));

const accountTypes = [
  {
    key: "CORPORATE",
    value: "CORPORATE",
  },
  {
    key: "INDIVIDUAL",
    value: "INDIVIDUAL",
  },
];

const Users: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("customer");
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([] as CustomerBackoffice[]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [pagination, setPagination] = useState({} as PartiesPagination);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  useEffect(() => {
    const getCustomers = async (): Promise<void> => {
      setLoading(true);

      try {
        const customerListRes = await fetchCustomerList({
          ...(search && { keyword: search }),
          pageNo,
          type,
          pageSize: TABLE_PAGE_SIZE,
        });

        setPagination(customerListRes?.pagination);
        setCustomers(customerListRes?.customerList);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCustomers();
  }, [pageNo, type, search]);

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () => downloadUsersCsv({ fileName: "users" }),
  });

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const columns: ColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "type",
      flex: 1,
      headerName: t("listTable__header_name__type"),
      renderCell: (params) => (
        <Typography>
          {params?.row?.type
            ? t(`customer__type__${params?.row?.type}`)
            : t("customer__type__generic_user")}
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: t("listTable__header_name__name"),
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "20px",
          }}
        >
          <span>{params.row.name}</span>

          <span>{params.row.surname}</span>
        </div>
      ),
    },

    {
      field: "mobileNumber",
      headerName: t("listTable__header_name__phone_number"),
      type: "number",
      flex: 1,
      sortable: false,
      valueFormatter: (params: CellParams) =>
        `${params?.row?.prefix as string} ${
          params?.row?.mobileNumber as string
        }`,
    },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "username",
      headerName: t("listTable__header_name__username"),
      flex: 1,
      valueFormatter: (params: CellParams) => {
        const customer = params?.row as CustomerBackoffice;
        return `${customer?.user?.username || "n/a"}`;
      },
    },
    {
      field: "locked",
      headerName: t("listTable__header_name__locked"),
      renderCell: (params) => (params.value ? <Lock /> : <LockOpen />),
      flex: 0.5,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => <DetailButton to={`/users/${params?.row?.id}`} />,
    },
  ];

  return (
    <>
      <>
        {documentLoading && (
          <div className={classes.downloadFile}>
            <Loader />
          </div>
        )}
      </>
      <MainTemplate>
        <div className={classes.customerContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("user__page__title")}</QuidTitle>
            </Grid>
          </Grid>
          <div className={classes.filterContainer}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  onClick={() => onCsvDownload()}
                  className={classes.csvBtn}
                >
                  <CsvIcon />
                </Button>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("customer__account__type")}
                  variant="outlined"
                  select
                  fullWidth
                  value={type}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setType(e.target.value)
                  }
                >
                  <MenuItem value="">{t("customer__account__type")}</MenuItem>
                  {accountTypes?.map(({ key, value }) => (
                    <MenuItem value={value}>{key}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={t("filter__search")}
                  variant="outlined"
                  fullWidth
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSearch(e.target.value)
                  }
                  value={search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className={classes.searchBox}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M15.7372 14.4753L12.7159 11.463C13.6907 10.2211 14.2196 8.68756 14.2176 7.10882C14.2176 5.70283 13.8007 4.32841 13.0196 3.15937C12.2385 1.99033 11.1282 1.07918 9.82925 0.54113C8.53028 0.00308012 7.10094 -0.137698 5.72196 0.136597C4.34299 0.410893 3.07631 1.08794 2.08213 2.08213C1.08794 3.07631 0.410893 4.34299 0.136597 5.72196C-0.137698 7.10094 0.00308012 8.53028 0.54113 9.82925C1.07918 11.1282 1.99033 12.2385 3.15937 13.0196C4.32841 13.8007 5.70283 14.2176 7.10882 14.2176C8.68756 14.2196 10.2211 13.6907 11.463 12.7159L14.4753 15.7372C14.5579 15.8204 14.6562 15.8865 14.7645 15.9317C14.8728 15.9768 14.9889 16 15.1062 16C15.2236 16 15.3397 15.9768 15.448 15.9317C15.5563 15.8865 15.6545 15.8204 15.7372 15.7372C15.8204 15.6545 15.8865 15.5563 15.9317 15.448C15.9768 15.3397 16 15.2236 16 15.1062C16 14.9889 15.9768 14.8728 15.9317 14.7645C15.8865 14.6562 15.8204 14.5579 15.7372 14.4753ZM1.77721 7.10882C1.77721 6.05433 2.0899 5.02352 2.67575 4.14674C3.26159 3.26996 4.09428 2.58659 5.0685 2.18305C6.04273 1.77952 7.11474 1.67393 8.14897 1.87965C9.1832 2.08538 10.1332 2.59316 10.8788 3.3388C11.6245 4.08444 12.1323 5.03444 12.338 6.06868C12.5437 7.10291 12.4381 8.17492 12.0346 9.14914C11.6311 10.1234 10.9477 10.9561 10.0709 11.5419C9.19413 12.1277 8.16332 12.4404 7.10882 12.4404C5.69479 12.4404 4.33867 11.8787 3.3388 10.8788C2.33893 9.87897 1.77721 8.52285 1.77721 7.10882Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <QuidDataGrid
            sortModel={[{ field: "id", sort: "desc" }]}
            disableSelectionOnClick
            onPageChange={onPageChange}
            loading={loading}
            columns={columns}
            rows={customers}
            rowCount={pagination?.totalEntries}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default Users;
