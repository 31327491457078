import { apiClient } from "@portit/core/utils/api/apiClient";
import {
  RewardInfoResponse,
  RewardListParams,
  RewardListResponse,
} from "entities/rewards/RewardEntity";
import { handleApiClientError } from "utils";

export async function fetchRewardsList(
  params: RewardListParams
): Promise<RewardListResponse> {
  try {
    const res = await apiClient.get<RewardListResponse>(
      `/rewards/v1/operation/bo/operations`,
      "company",
      {
        page: params.page,
        size: 9,
        order: "id",
        orderType: "desc",
        ...(params.currency && { currency: params.currency }),
        ...(params.keyword && { keyword: params.keyword }),
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchRewardsInformationsByWalletId(
  params: RewardListParams
): Promise<RewardInfoResponse> {
  try {
    const res = await apiClient.get<RewardInfoResponse>(
      `/rewards/v1/operation/bo/reward/wallet/${params.wallet_id}`,
      "company"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
