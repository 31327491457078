import { Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import { useState, useEffect } from "react";
import { handleFailure } from "resHandlers";
import { getProducts } from "api/product";
import { useDispatch } from "react-redux";
import { getKYCLevelsByProductId } from "@portit/core/api/Products";
import {
  KYCLevel,
  KYCLevelsResponse,
  ProductDetailResponse,
} from "@portit/core/entities/Products";
import ArrowIcon from "components/atoms/icons/ArrowIcon";
import StatusBadge from "components/atoms/StatusBadge";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  block: {
    paddingTop: 15,
    paddingRight: 50,
    paddingLeft: 20,
    cursor: "pointer",
    width: "50%",
  },
  blockInside: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    background: "#fff",
    padding: "5px 40px",
    borderRadius: 31,
    boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.1)",
    minHeight: "50px",
    justifyContent: "space-between",
    marginLeft: "10px",
  },
  container: {
    paddingBottom: "60px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "start",
  },
  productsBlock: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "start",
  },
}));

const KYCLevelsPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("customer");
  const customerId: number = parseInt(match.params.id, 10);
  const classes = useStyles();
  const [products, setProducts] = useState<ProductDetailResponse[]>([]);
  const [selectedProduct, setSelecetdProduct] = useState({
    index: 1,
    name: "Company",
  });
  const [kyc, setKyc] = useState<KYCLevelsResponse[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProducts();
  }, [customerId]);

  const fetchProducts = async () => {
    try {
      const res = await getProducts();
      setProducts(res.products);
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const fetchKYC = async () => {
    try {
      const kycResponse = await getKYCLevelsByProductId(
        customerId,
        selectedProduct.index
      );
      setKyc([...kyc, kycResponse]);
    } catch (err: any) {
      handleFailure(err);
    }
  };

  useEffect(() => {
    fetchKYC();
  }, [dispatch, selectedProduct]);

  return (
    <MainTemplate>
      <QuidTitle className={classes.root}>
        {t("customer__verification__title__kyc_levels")}
      </QuidTitle>
      <div className={classes.container}>
        <div className={classes.productsBlock}>
          <Typography variant="h3" color="textPrimary">
            Products
          </Typography>
          {products?.map((product: ProductDetailResponse) => {
            return (
              <div
                className={classes.block}
                onClick={() => {
                  setSelecetdProduct({
                    index: product.id,
                    name: product.description,
                  });
                }}
              >
                <div className={classes.blockInside}>
                  <Typography variant="body1" color="textSecondary">
                    {product.description}
                  </Typography>
                  <ArrowIcon />
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.productsBlock}>
          <Typography variant="h3" color="textPrimary">
            {selectedProduct.name}
          </Typography>
          {kyc?.map((kycResponse: KYCLevelsResponse, index: number) => {
            return (
              <>
                {kycResponse?.kycLevels?.map((kycLevel: KYCLevel) => {
                  if (index + 1 === selectedProduct.index) {
                    return (
                      <div className={classes.block}>
                        <div className={classes.blockInside}>
                          <Typography variant="body1" color="textSecondary">
                            {kycLevel.name}
                          </Typography>
                          <StatusBadge status={kycLevel.status} />
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            );
          })}
        </div>
      </div>
    </MainTemplate>
  );
};

export default KYCLevelsPage;
